import React from "react";

import {Alert, Button, Card, CardHeader, Stack} from "@mui/material";
import CardContent from "@mui/material/CardContent";

import {yupResolver} from "@hookform/resolvers/yup";
import themeMuiV5 from "components/common/ThemeMuiV5";
import "draft-js/dist/Draft.css";
import { navigate, useIntl} from "gatsby-plugin-intl";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useForm} from "react-hook-form";
import {GROUP} from "utils/constants/back-office-constants";

import AddEditInfosBloc from "components/backoffice/academics/AddEditInfosBloc";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import AddEditMultiValuesBloc from "components/backoffice/academics/AddEditMultiValuesBloc";
import {RhfListOfValuesSelect, RhfSwitch, RhfTextField, RhfTextFieldMultiline} from 'components/backoffice/common/RhfFields';
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import {AvatarUpload} from "ui/AvatarUpload";
import {ListOfValues} from "ui/ListOfValues";
import { validateAcademicToAdd, validateAcademicToEdit } from "utils/academics-schema";
import academicService from "services/api/academicService";
import Seo from "components/common/Seo";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";


const useStyles = {
    rowFormStack: {
        marginLeft: 0,
        marginTop: 2,
        spacing: 12,
        alignItems: "center",
        justifyContent: "center"
    },
    avatarStyles: {
        container: {
            spacing: 4,
            justifyContent: "center",
            alignItems: "center"
        },
        avatarSx: {
            width: 90,
            height: 90
        },
    },
    biographyField: {
        rows: 6,
        sx: {
            height: 100,
            width: 880
        }
    },
    universityBloc: {
        isVisibleStack: {
            width: "30ch",
            alignItems: "center"
        },
        universitySx: {
            width: "30ch",
            justifyContent: "center",
        },
        unitSx: {
            width: "30ch",
            justifyContent: "center",
        }
    },
    identityBloc: {
        textFieldSx: {
            width: "30ch",
            justifyContent: "center",
            alignContent: "center"
        }
    },
    multiValuesSx: {
        listOfValuesClass: {
            width: "30ch",
            justifyContent: "center"
        },
    },
};


const AcademicAddEditPage = ({ academicId}) => {
    const intl = useIntl();
    const isEditPage = academicId ? true : false;
    const [linksByPropertyMap, setLinksByPropertyMap] = React.useState(new Map());

    const mapAcademicBack2Front = (academic) => {
        let academicMapped = {
            academicId: academic.academicId,
            userId: academic.userId,
            mediaId: academic.photo?.mediaId,
            picture: academic.photo,
            srcPicture: academic.photo?`data:image/jpeg;base64,`+ academic.photo.stream : null,
            visible: academic.visible,
            lastConnectionDate: academic.lastConnectionDate, 
            firstName: academic.firstName,
            lastName: academic.lastName,
            email: academic.email,
            description: academic.description,
            universityId: academic.universityId,
            universityUnitId: academic.universityUnitId,
            academicTitles: academic.titles,
            academicDisciplines: academic.academicDisciplines,
            expertiseFields: academic.fieldsOfExpertise,
            workingLanguages: academic.workingLanguages,
            researchCountries: academic.countriesOfResearch,
            researchProjects: academic.researchProjects
        };
        if(academic.informationLinks) {
            let linksObject = convertLinkList2LinksObject(academic.informationLinks);
            academicMapped.links = linksObject;
        }    
        return academicMapped;
    }

    const convertLinkList2LinksObject = (dbLinkList) => {
        const linkCodeByProperty = {
            linkPublicationWebPage: 'PUBWB',
            linkPublicationFeedLink: 'PUBFL',
            linkOrcid: 'ORC',
            linkProWebPage: 'PROWB',
            linkPersoWebPage: 'PERWB',
            linkGoogleScholar: 'GS',
            linkResearchGateProfile: 'RG',
            linkLinkedIn: 'LI',
            linkTwitter: 'TW'
        };
        const dbLinksByCodeMap = new Map(dbLinkList.map(infoLink => [infoLink.type, infoLink]));
        let dbLinksByPropertyMap = new Map();
        let linksObject = {};
        for (const [property, code] of Object.entries(linkCodeByProperty)) {
            const dbLink = dbLinksByCodeMap.get(code);
            if (dbLink) {
                linksObject[property] = dbLink?.url;
                dbLinksByPropertyMap.set(property, dbLink);
            }
        }
        setLinksByPropertyMap(dbLinksByPropertyMap);
        return linksObject;
    }

    const mapAcademicFront2Back = (academic, isEditPage) => {
        let informationLinks = [];
        for (const [key, value] of Object.entries(academic.links)) {
            let infoLink = {"linkId": null, "code": key, "url": value};
            if (isEditPage) {
                const dbLink = linksByPropertyMap.get(key);
                infoLink.linkId = dbLink?.linkId;
            }
            informationLinks.push(infoLink);
        }
        academic.informationLinks = informationLinks;
        return academic;
    }

    let academicDefaultValues = {
        picture: null,
        srcPicture: null,
        visible: false,
        firstName: '',
        lastName: '',
        email: '',
        description: '',
        universityId: '',
        universityUnitId: '',
        academicTitles: [],
        academicDisciplines: [],
        expertiseFields: [],
        workingLanguages: [],
        researchCountries: [],
        researchProjects: [],
        links: {
            linkPublicationWebPage: '',
            linkPublicationFeedLink: '',
            linkOrcid: '',
            linkProWebPage: '',
            linkPersoWebPage: '',
            linkGoogleScholar: '',
            linkResearchGateProfile: '',
            linkLinkedIn: '',
            linkTwitter: ''
        }
    };

    const useFormHook = useForm(
        {
            resolver: yupResolver(isEditPage ? validateAcademicToEdit : validateAcademicToAdd),
            defaultValues: academicDefaultValues,
            mode: "onChange",
        }
    );


    const [resultMessage, setResultMessage] = React.useState("");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [unitList, setUnitList] = React.useState([]);
    const [lastConnectionDate, setLastConnectionDate] = React.useState(null);
    
    React.useEffect(() => {
        const fetchAcademicData = async () => {
            try {
                const results = await academicService.searchAcademicById(academicId);
                const dbAcademic = results.data;
                let editAcademic = mapAcademicBack2Front(dbAcademic);
                await updateUniversityUnitList(editAcademic.universityId);
                useFormHook.reset(editAcademic);
                setLastConnectionDate(editAcademic.lastConnectionDate);
            } catch (error) {
                console.log("error : ", error);
                navigate("/back-office/faculty");
            }
        };
        if (!!academicId) {
            fetchAcademicData();
        }
    }, [academicId]);



    const onSubmit = academic => {
        setDisableSubmit(true);
        setOpenSuccess(false);
        setOpenError(false);
        academic = mapAcademicFront2Back(academic, isEditPage);
        academicService
            .saveAcademicWithPicture(academic)
            .then((result) => {
                setResultMessage("academic.create.success");
                setOpenSuccess(true);
                setDisableSubmit(false);
                goBack();
            })
            .catch((error) => {
                handleError(error);
            });

    };

    const handleError = (error) => {
        let errLabel = "academic.create.error";
        if (error && error.data && error.data.errorCode) {
            console.log("error : ", error);
            errLabel = error.data.errorCode;
        }
        console.log("errLabel : ", errLabel);
        setResultMessage(errLabel);
        setOpenError(true);
        setDisableSubmit(false);
    }

    const goBack = () => {
        try {
            window.history.back();
        } catch (error) {
            console.log("error : ", error);
        }
    };


    const onError = (errors, e) => console.log(errors, e);

    const onChangeUniversity = async (university) => {
        await updateUniversityUnitList(university.value);
    }

    const updateUniversityUnitList = async (universityId) => {
        await academicService.findUnitsByUniversityId(universityId)
            .then(response => {
                const univUnitList = response.data;
                if (univUnitList) {
                    setUnitList(univUnitList);
                } else {
                    console.log("no data for university id : ", universityId);
                }
            }).catch(error => {
                console.log("error : ", error);
            });
    }    
    return (
        <LayoutBo>
            <Seo title={intl.formatMessage({id: "academic.edit"})} lang={intl.locale}/>
            <Card sx={{
                backgroundColor: themeMuiV5.palette.background.paper,
                maxHeight: "100%",
                paddingTop: themeMuiV5.spacing(1),
                color: themeMuiV5.palette.primary.main
            }}>

                <CardHeader sx={{
                    backgroundColor: themeMuiV5.palette.primary.main,
                    textAlign: "center",
                    paddingTop: themeMuiV5.spacing(1),
                    color: themeMuiV5.palette.primary.contrastText
                }}
                            title={
                                isEditPage
                                    ? intl.formatMessage({id: "academic.edit"})
                                    : intl.formatMessage({id: "academic.new"})
                            }/>

                <CardContent sx={{backgroundColor: themeMuiV5.palette.background.paper}}>

                    <ResultSnackbars
                        openError={openError}
                        openSuccess={openSuccess}
                        messageId={resultMessage}
                        setOpenSuccess={setOpenSuccess}
                        setOpenError={setOpenError}
                    />        

                    <form onSubmit={useFormHook.handleSubmit(onSubmit, onError)}>

                        { lastConnectionDate != null ?
                            <Alert severity="warning" sx={{ width: '100%', margin: '10px', padding: '1px' }}>
                                {intl.formatMessage({id: "academic.edit.infoMessage.namesNotEditable"})}
                            </Alert>:
                            <></>
                        }   

                        <Stack id="form-row-1-biography" direction="row"
                               spacing={useStyles.rowFormStack.spacing}
                               marginLeft={useStyles.rowFormStack.marginLeft}
                               justifyContent={useStyles.rowFormStack.justifyContent}
                               alignItems={"top"}>

                            
                            <AvatarUpload
                                pictureFileName={"picture"}
                                pictureSourceName={"srcPicture"}
                                useFormHook={useFormHook}
                                styles={useStyles.avatarStyles}/>

                            <RhfTextFieldMultiline
                                label="academic.description"
                                name="description"
                                //required={true}
                                control={useFormHook.control}
                                rows={useStyles.biographyField.rows}
                                multiline={true}
                                error={useFormHook.formState.errors ? useFormHook.formState.errors.description : null}
                                maxLength={3000}
                                sx={useStyles.biographyField.sx}/>

                        </Stack>

                        <Stack id="form-row-2-university" direction="row"
                               spacing={useStyles.rowFormStack.spacing}
                               marginLeft={useStyles.rowFormStack.marginLeft}
                               marginTop={6}
                               justifyContent={useStyles.rowFormStack.justifyContent}
                               alignItems={useStyles.rowFormStack.alignItems}>

                            <Stack
                                width={useStyles.universityBloc.isVisibleStack.width}
                                alignItems={useStyles.universityBloc.isVisibleStack.alignItems}
                            >
                                <RhfSwitch
                                    label="academic.visible"
                                    name="visible"
                                    control={useFormHook.control}/>
                            </Stack>

                            <RhfListOfValuesSelect
                                name="universityId"
                                label={"academic.university"}
                                //required={true}
                                sx={useStyles.universityBloc.universitySx}
                                group={GROUP.university}
                                control={useFormHook.control}
                                onChangeField={onChangeUniversity}
                                error={useFormHook.formState.errors ? useFormHook.formState.errors.universityId : null}/>

                            <ListOfValues
                                name="universityUnitId"
                                label={"academic.universityUnit"}
                                //required={true}
                                items={unitList}
                                sx={useStyles.universityBloc.unitSx}
                                group={GROUP.universityUnit}
                                control={useFormHook.control}
                                error={useFormHook.formState.errors ? useFormHook.formState.errors.universityUnitId : null}/>

                        </Stack>

                        <Stack id="form-row-3-identity" direction="row"
                               spacing={useStyles.rowFormStack.spacing}
                               marginLeft={useStyles.rowFormStack.marginLeft}
                               marginTop={useStyles.rowFormStack.marginTop}
                               justifyContent={useStyles.rowFormStack.justifyContent}
                               alignItems={useStyles.rowFormStack.alignItems}>
                                 
                            <RhfTextField
                                label="academic.lastName"
                                name="lastName"
                                textTransform="uppercase"
                                //required={true}
                                disabled={lastConnectionDate!=null}
                                sx={useStyles.identityBloc.textFieldSx}
                                control={useFormHook.control}
                                error={useFormHook.formState.errors ? useFormHook.formState.errors.lastName : null}/>


                            <RhfTextField
                                label="academic.firstName"
                                name="firstName"
                                textTransform="capitalize"
                                //required={true}
                                disabled={lastConnectionDate!=null}
                                sx={useStyles.identityBloc.textFieldSx}
                                control={useFormHook.control}
                                error={useFormHook.formState.errors ? useFormHook.formState.errors.firstName : null}/>


                            <RhfTextField
                                label="academic.email"
                                name="email"
                                disabled={isEditPage}
                                //required={true}
                                sx={useStyles.identityBloc.textFieldSx}
                                control={useFormHook.control}
                                error={useFormHook.formState.errors ? useFormHook.formState.errors.email : null}/>

                        </Stack>

                        <AddEditMultiValuesBloc id="form-multivalues-bloc" useFormHook={useFormHook}
                                                rowFormStack={useStyles.rowFormStack}/>

                        <AddEditInfosBloc id="form-info-bloc" useFormHook={useFormHook}
                                          rowFormStack={useStyles.rowFormStack}/>

                        <Stack direction="row" spacing={5} margin={5} justifyContent={"center"}>
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                disabled={disableSubmit}
                            >
                                {intl.formatMessage({id: "academic.save"})}
                            </Button>
                            <ConfirmationDialog
                                isModified={useFormHook.formState.isDirty}
                                listingUrl={`/back-office/faculty`}
                            />
                        </Stack>

                    </form>
                </CardContent>
            </Card>
        </LayoutBo>
    );
};

export default AcademicAddEditPage;



