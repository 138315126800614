import React from "react";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";

const LibraryPage = () => {

    return (
        <>
            <LayoutBo>
                <h1>Library Page</h1>
            </LayoutBo>    
        </>
    );

}

export default LibraryPage;