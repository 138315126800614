import React from "react";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";

const DashboardBoPage = () => {

    return (
        <>
            <LayoutBo>
                <h1>Welcome to Back Office Home Page</h1>
            </LayoutBo>    
        </>
    );

}

export default DashboardBoPage;