
import React from 'react';
import * as headerStyles from 'styles/backoffice/header.module.scss';

import { AppBar, Container, Fade, Grid, Menu, MenuItem, Toolbar, Typography } from '@mui/material';

import { faCaretSquareDown } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeLocale, IntlContextConsumer, Link, useIntl } from 'gatsby-plugin-intl';

import { languageName } from 'utils/constants/constants';
import { getDisplayName, isLoggedIn, logout } from 'services/auth';

import { CivicaLogo } from 'components/layout/CivicaLogo';
import MainNavbar from 'components/backoffice/common/layout/header/navbars/MainNavbar';
import MobileNavbar from 'components/backoffice/common/layout/header/navbars/MobileNavbar';


const HeaderBo = ({inverseBackground}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const intl = useIntl();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLanguageSelection = (language) => {
        setAnchorEl(null);
        if (language && language.toLowerCase().startsWith('en')) {
            changeLocale('en-gb');
        } else {
            changeLocale(language);
        }
    }

    const handleLogout = () => {
        logout();
    }

    const user = getDisplayName();

    return (
        <Container className={headerStyles.root}>
                      
            <Grid container role="navigation" className={headerStyles.languageBar}>
                
                <Grid item className={headerStyles.languageBarItem}>
                    <Typography variant="caption">
                    <span tabIndex={0} className="fakeLinkForAccessibility"
                            onClick={handleClick}
                            
                    >{intl.formatMessage({id: "header.languages"})} <FontAwesomeIcon
                        icon={faCaretSquareDown}></FontAwesomeIcon></span>
                    </Typography>
                </Grid>

                    <Grid item className={headerStyles.languageBarItem}>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <IntlContextConsumer>
                            {({languages, language: currentLocale}) =>
                                languages.map(language => (
                                    <MenuItem
                                        className="fakeLinkForAccessibility"
                                        tabIndex={0}
                                        key={language}
                                        onClick={() => handleLanguageSelection(language)}
                                        style={{color: currentLocale === language ? '#244f9d' : '#000'}}
                                    >
                                        {languageName[language]}
                                    </MenuItem>
                                ))
                            }
                        </IntlContextConsumer>
                    </Menu>
                </Grid>

                {isLoggedIn() &&
                <>
                    <Grid item className={headerStyles.languageBarItem}>
                        <Typography
                            variant="caption">{intl.formatMessage({id: "header.welcome"})} {user}</Typography>
                    </Grid>

                    <Grid item className={headerStyles.languageBarItem}>
                        <div
                            tabIndex={0} className="fakeLinkForAccessibility" onKeyPress={handleClick}
                            aria-label="Se déconnecter"
                            onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon></div>
                    </Grid>
                </>
                }
            </Grid>

            <AppBar position="relative" className={headerStyles.appBar}>
                <Toolbar variant="regular" >
                    <Link to="/">
                    <div className={headerStyles.logo}>
                        <CivicaLogo />
                    </div>
                    </Link>
                    <div className={headerStyles.grow}/>
                    { isLoggedIn() && 
                        <>
                            <MainNavbar />  
                            <MobileNavbar />
                        </>                     
                    }
                </Toolbar>
            </AppBar>
        
        </Container>
    );
}
export default HeaderBo;





