import React, {Fragment} from "react";

import {IconButton, Snackbar} from "@mui/material";
import { Alert } from '@mui/material';

import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {injectIntl} from "gatsby-plugin-intl";

const CustomSnackbar = ({ 
                          intl,
                          severity="success", 
                          position={ vertical: 'top', horizontal: 'center' },
                          open, 
                          setOpen, 
                          messageStr=null, 
                          messageId=null, 
                          autoHideDuration=6000 
}) => {
  return (
    <Fragment>
      {open && (
        <Snackbar open={open}
                  autoHideDuration={autoHideDuration}
                  onClose={() => setOpen(false)}
                  anchorOrigin={position}
        >
          <Alert
            severity={severity}
            onClose={() => setOpen(false)}
            sx={{ whiteSpace: 'pre-line' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </IconButton>
            }
          >
            { messageId && intl.formatMessage({ id: messageId }) }
            { messageStr && messageStr }
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  );
};

export default injectIntl(CustomSnackbar);
