import React from "react";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";

const CertificatesPage = () => {

    return (
        <>
            <LayoutBo>
                <h1>Certificates Page</h1>
            </LayoutBo>    
        </>
    );

}

export default CertificatesPage;