import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Card, 
    CardContent, Divider, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useForm} from "react-hook-form";
import {injectIntl} from "gatsby-plugin-intl";

import Seo from "components/common/Seo";
import CustomDataGrid from 'components/backoffice/common/CustomDataGrid';
import { RhfListOfValuesSelect, RhfRadioButtonAll, RhfTextField } from 'components/backoffice/common/RhfFields';
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import { GROUP, PUBLIC_PROFILE } from "utils/constants/back-office-constants";
import courseService from "services/api/courseService";
import newsService from "services/api/newsService";


const LOCAL_STORAGE_SEARCH_CRITERIA = "backoffice.news.searchCriteria";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20,
    },
    details: {
        alignItems: "center",
    },
    column: {
        flexBasis: "33.33%",
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    textField: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        width: '95%',
    },
    formControlLabel: {
        verticalAlign: "bottom",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(8),
    },
    card: {
        maxHeight: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
        backgroundColor: theme.palette.background.paper,
    },
    cardHeader: {
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        "&:hover": {
            borderWidth: "0 0 3px",
            borderStyle: "solid",
            borderColor: "#20a842",
        },

    },
}));

const NewsListPage = ({intl}) => {
    const columns = [
        {
            field: "title",
            headerName: intl.formatMessage({id: "opportunity.title"}),
            flex: 5,
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            field: "type",
            headerName: intl.formatMessage({id: "opportunity.type"}),
            flex: 1,
            renderCell: (params) => {
                return intl.formatMessage({
                    id: courseService.getLabelKeyByGroupAndCode(
                        GROUP.opportunityType,
                        params.value
                    ),
                });
            },
        },
        {
            field: "startDate",
            headerName: intl.formatMessage({id: "opportunity.start.date"}),
            width: 165,
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            field: "endDate",
            headerName: intl.formatMessage({id: "opportunity.end.date"}),
            width: 165,
            renderCell: (params) => {
                return params.value;
            }
        }
    ];

    const editColumnParams = {
        field: "id",
        arialLabel: "modify opportunity",
        url: "/back-office/opportunities/edit"
    }

    const useFormMethods = useForm({
        defaultValues: {
            title: "",
            type: "",
            publicCycle: "",
            publicProfile: "",
            //   startDate: "",
            //   endDate: "",
            visible: ""
        },
    });

    const classes = useStyles();
    const pageSize = 50;

    const [totalNumberOfRow, setTotalNumberOfRow] = React.useState(0);
    const searchCriteria = React.useRef({});

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectionModel, setSelectionModle] = React.useState([]);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [page, setPage] = React.useState(0);

    const submitForm = (formData) => {
        searchCriteria.current = formData;
        localStorage.setItem(LOCAL_STORAGE_SEARCH_CRITERIA, JSON.stringify(searchCriteria.current));
        loadServerRows(0, []);
    };

    const resetForm = () => {
        useFormMethods.reset();
        searchCriteria.current = useFormMethods.getValues();
        localStorage.removeItem(LOCAL_STORAGE_SEARCH_CRITERIA);
        loadServerRows(0, []);
    };

    const loadServerRows = React.useCallback(async (page, sortModel) => {
        setDisableSubmit(true);
        setLoading(true);
        const response = await newsService.searchByCriteria(
            searchCriteria.current,
            page,
            pageSize,
            sortModel
        );
        let news = [];
        if(response?.data?.content){
            news = response.data.content.map(row => (
                { ...row, 
                    deletable: true,
                    editable: true
                }
            ));
        }
        setPage(page);
        setTotalNumberOfRow(response?.data?.totalSize);
        setRows(news);
        setDisableSubmit(false);
        setLoading(false);
    }, []);

    const handleDelete = async (selection) => {
        // delete selected rows
        await newsService.deleteOpportunities(selection).then(() => {
            setRows(
                rows.filter((r) => selection.filter((sr) => sr === r.id).length < 1)
            );
            setSelectionModle([]);
        });
    };

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModle(newSelectionModel);
    };

    let displayCycle = useFormMethods.watch("publicProfile") === PUBLIC_PROFILE.student;


    React.useEffect(() => {
        let item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_CRITERIA));
        if(item){
            searchCriteria.current = item;
            Object.keys(item).forEach(key => {
                useFormMethods.setValue(key, item[key]);
            });
        }
        loadServerRows(0, []);
        setRows([]);
    }, [loadServerRows]);

    return (
        <LayoutBo>
            <Seo
                title={intl.formatMessage({id: "course.catalog"})}
                lang={intl.locale}
            />
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <form onSubmit={useFormMethods.handleSubmit(submitForm)} noValidate>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        id="opportunities-search-header"
                                    >
                                        <div className={classes.column}>
                                            <Typography className={classes.heading}>
                                                {intl.formatMessage({id: "backoffice.filter.title"})}
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.details}>
                                        <Grid container>
                                            <Grid item xl={3} md={3} sm={12}>
                                                <RhfTextField
                                                    name="title"
                                                    label={"opportunity.title"}
                                                    control={useFormMethods.control}
                                                    className={classes.textField}
                                                    id="filter-title-text"
                                                />
                                            </Grid>
                                            <Grid item xl={3} md={3} sm={12}>
                                                <RhfListOfValuesSelect
                                                    name="type"
                                                    label={"opportunity.type"}
                                                    group={GROUP.opportunityType}
                                                    control={useFormMethods.control}
                                                    className={classes.textField}
                                                    id="filter-type-select"
                                                />
                                            </Grid>
                                            <Grid item xl={3} md={3} sm={12}>
                                                <RhfListOfValuesSelect
                                                    name="publicProfile"
                                                    label={"opportunity.public.profile"}
                                                    group={GROUP.roleGroup}
                                                    control={useFormMethods.control}
                                                    className={classes.textField}
                                                    id="filter-profile-select"
                                                />
                                            </Grid>
                                            <Grid item xl={3} md={3} sm={12}>
                                                {displayCycle && (
                                                    <RhfListOfValuesSelect
                                                        name="publicCycle"
                                                        label={"opportunity.public.cycle"}
                                                        group={GROUP.role}
                                                        control={useFormMethods.control}
                                                        className={classes.textField}
                                                        id="filter-cycle-select"
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RhfRadioButtonAll
                                                    name="visible"
                                                    label="opportunity.visible"
                                                    control={useFormMethods.control}
                                                    id={"radio-visible"}
                                                    defaultValue=""
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    <Divider/>
                                    <AccordionActions style={
                                        {
                                            justifyContent: 'center'
                                        }
                                    }>
                                        <Button color="secondary" onClick={resetForm}>
                                            {intl.formatMessage({id: "course.filter.reset"})}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={disableSubmit}
                                        >
                                            {intl.formatMessage({id: "course.filter.search"})}
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </form>
                    <CustomDataGrid
                        columns={columns}
                        editColumnParams={editColumnParams}
                        onDelete={handleDelete}
                        loadServerRows={loadServerRows}
                        pageSize={pageSize}
                        totalRows={totalNumberOfRow}
                        changeSelection={handleSelectionChange}
                        selectionModel={selectionModel}
                        rows={rows}
                        loading={loading}
                        rowDoubleClickLink="/back-office/opportunities/edit"
                        addMessage={"opportunity.add"}
                        addLink="/back-office/opportunities/new"
                        page={page}
                    />

                </CardContent>
            </Card>
        </LayoutBo>

    );
};

export default injectIntl(NewsListPage);
