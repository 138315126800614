import React, {Fragment} from "react";

import {Button, FormControl, FormGroup, FormLabel, Grid,} from "@mui/material";

import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFieldArray, useFormContext} from "react-hook-form";
import {injectIntl} from "gatsby-plugin-intl";

import {getUserRole} from "../../../services/auth";
import {INPUT_MAX_LENGTH, ROLE} from "../../../utils/constants/constants";
import {GROUP} from "../../../utils/constants/back-office-constants";
import {DISABLED_CONDITION} from "../../../utils/courses-schema";
import {
    MultiLinkSelect,
    MultiTeacherSelect,
    RhfDatePicker,
    RhfListOfValuesCheckboxes,
    RhfListOfValuesSelect,
    RhfSwitch,
    RhfTextField,
} from 'components/backoffice/common/RhfFields';

const AddEditCourse = ({ classes, control, intl }) => {
  const methods = useFormContext();
  const courseType = methods.watch("type");
  const {
    formState: { errors },
    register,
  } = methods;

  const {
    fields: topicFields,
    append: topicAppend,
    remove: topicRemove,
  } = useFieldArray({ name: "topics", control });

  const isAdmin = getUserRole().includes(ROLE.admin);

  return (
    <Grid container spacing={3}>
      <Grid item xl={3} md={4} xs={12}>
        <RhfListOfValuesSelect
          name="university"
          label={"course.university"}
          group={GROUP.university}
          control={methods.control}
          className={classes.textField}
          required
          id="course-university-select"
          error={errors ? errors.university : null}
        />
      </Grid>
      <Grid item xl={9} md={8} xs={12}>
        <RhfListOfValuesSelect
          name="courseLanguage"
          label="course.course.language"
          group={GROUP.language}
          control={methods.control}
          className={classes.textField}
          required={DISABLED_CONDITION["course.courseLanguage"].required(courseType)}
          error={errors ? errors.courseLanguage : null}
          id="course-course-language-select"
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfListOfValuesSelect
          name="type"
          label={"course.type"}
          group={GROUP.courseType}
          control={methods.control}
          className={classes.textField}
          required
          id="course-type-select"
          error={errors ? errors.type : null}
        />
      </Grid>
      <Grid item xl={9} md={8} sm={12} xs={12}>
        <RhfListOfValuesSelect
          name="subtype"
          label="course.subtype"
          group={GROUP.courseSubtype}
          control={methods.control}
          className={classes.textField}
          disabled={DISABLED_CONDITION["course.subtype"].disabled(courseType)}
          required={DISABLED_CONDITION["course.subtype"].required(courseType)}
          id="course-subtype-select"
          error={errors ? errors.subtype : null}
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfTextField
          label="course.code"
          name="code"
          className={classes.textField}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.code"].disabled(courseType)}
          required={DISABLED_CONDITION["course.code"].required(courseType)}
          id="course-code-text"
          error={errors ? errors.code : null}
        ></RhfTextField>
      </Grid>
      <Grid item xl={9} md={8} sm={12} xs={12}>
        <RhfSwitch
          label="course.engage.course"
          name="engageCourse"
          className={classes.formControlLabel}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.engageCourse"].disabled(
            courseType
          )}
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfTextField
          label="course.key"
          name="key"
          className={classes.textField}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.pkey"].disabled(courseType)}
          required={DISABLED_CONDITION["course.pkey"].required(courseType)}
          error={errors ? errors.key : null}
          id="course-key-text"
        />
      </Grid>
      <Grid item xl={9} md={8} sm={12} xs={12}>
        <RhfSwitch
          label="course.private.course"
          name="privateCourse"
          className={classes.formControlLabel}
          control={methods.control}
          // required
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfTextField
          label="course.multi.join.code"
          name="multiJoinCode"
          className={classes.textField}
          control={methods.control}
          id="course-multi-code-text"
          disabled={DISABLED_CONDITION["course.multiJoinCode"].disabled(
            courseType
          )}
          required={DISABLED_CONDITION["course.multiJoinCode"].required(
            courseType
          )}
          error={errors ? errors.multiJoinCode : null}
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfSwitch
          label="course.open.to.registration"
          name="openToRegistration"
          className={classes.formControlLabel}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.openToRegistration"].disabled(
            courseType
          )}
          required={DISABLED_CONDITION["course.openToRegistration"].required(
            courseType
          )}
        />
      </Grid>
      <Grid item xl={6} md={4} sm={12} xs={12}>
        <RhfTextField
          label="course.max.registration"
          name="maxRegistration"
          className={classes.textField}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.maxRegistration"].disabled(
            courseType
          )}
          id="course-max-registration-text"
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
          <RhfListOfValuesSelect
              id="course-period-select"
              name="period"
              label="course.period"
              group={GROUP.period}
              control={methods.control}
              className={classes.textField}
              disabled={DISABLED_CONDITION["course.period"].disabled(courseType)}
              required={DISABLED_CONDITION["course.period"].required(courseType)}
              error={errors ? errors.period : null}
          />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfSwitch
          label="course.visible"
          name="visible"
          className={classes.formControlLabel}
          control={methods.control}
        />
      </Grid>
      <Grid item xl={6} md={4} sm={12} xs={12}>
        <RhfDatePicker
          control={methods.control}
          name="deadlineRegistration"
          label="course.deadline.registration"
          id="course-deadline-date"
          format="dd/MM/yyyy"
          className={classes.textField}
          disabled={DISABLED_CONDITION["course.registrationDeadline"].disabled(
            courseType
          )}       
          />
      </Grid>
      {/* {isAdmin ? ( */}
        {/* <Fragment>
        <Grid item xl={3} md={4} sm={12} xs={12}>
        </Grid>
        <Grid item xl={9} md={8} sm={12} xs={12}>
          <RhfSwitch
            label="course.leader"
            name="leader"
            disabled={DISABLED_CONDITION["course.leader"].disabled(courseType)}
            className={classes.formControlLabel}
            control={methods.control}
          />
        </Grid>
        </Fragment> */}
      {/* ) :null} */}
        <Grid item xl={12} md={12} sm={12} xs={12}>
            <RhfListOfValuesSelect
                name="logo"
                label={"course.logo"}
                group={GROUP.logo}
                control={methods.control}
                className={classes.textField}
                required
                id="course-logo"
                error={errors ? errors.logo : null}
            />
        </Grid>
      <Grid item xl={12} md={12} sm={12} xs={12}>
        <RhfTextField
          label="course.title"
          name="title"
          className={classes.textFieldLarge}
          control={methods.control}
          required
          id="course-title-text"
          error={errors ? errors.title : null}
          maxLength={INPUT_MAX_LENGTH.COURSE_TITLE}
        />
      </Grid>
      <Grid item xl={12} md={12} sm={12} xs={12}>
        <input
          id="description-id-text"
          {...register("descriptionId")}
          type="hidden"
        />
        <RhfTextField
          label="course.short.description"
          name="shortDescription"
          className={classes.textFieldLarge}
          control={methods.control}
          multiline={true}
          rows={5}
          required={DISABLED_CONDITION["course.shortDescription"].required(
            courseType
          )}
          error={errors ? errors.shortDescription : null}
          id="course-short-descirption-text"
          maxLength={INPUT_MAX_LENGTH.COURSE_SHORT_DESCRIPTION}

        />
      </Grid>
      <Grid item xl={12} md={12} sm={12} xs={12}>
        <RhfTextField
          label="course.long.description"
          name="longDescription"
          className={classes.textFieldLarge}
          control={methods.control}
          multiline={true}
          rows={15}
          id="course-long-descirption-text"
          maxLength={INPUT_MAX_LENGTH.COURSE_LONG_DESCRIPTION}
        />
      </Grid>
      <Grid item xl={4} md={5} sm={12} xs={12}>
        <RhfListOfValuesSelect
          name="language"
          label="course.descriptionLanguage"
          group={GROUP.language}
          control={methods.control}
          className={classes.textField}
          required={DISABLED_CONDITION["course.descriptionLanguage"].required(
            courseType
          )}
          error={errors ? errors.language : null}
          id="course-language-select"
        />
      </Grid>
      <Grid item xl={8} md={7} sm={12} xs={12}>
        <RhfListOfValuesCheckboxes
          name="cycles"
          label="course.cycle"
          className={classes.formControl}
          getValues={methods.getValues}
          control={methods.control}
          group={GROUP.courseCycle}
          required={true}
          error={errors ? errors.cycles : null}
        />
      </Grid>
      <Grid item xl={12} md={12} sm={12} xs={12}>
        {/* <FormControl component="fieldset"> */}
          <FormLabel
            component="legend"
            required={DISABLED_CONDITION["course.links"].required(courseType)}
            className={"Mui-required"}
          >
            {intl.formatMessage({ id: "course.links" })}
          </FormLabel>
          <MultiLinkSelect
            control={methods.control}
            name="links"
            className={classes.textField}
            register={methods.register}
          />
        {/* </FormControl> */}
      </Grid>
      <Grid item xl={4} md={6} sm={12} xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {intl.formatMessage({ id: "course.topics" })}
          </FormLabel>
          {topicFields.map((topic, i) => {
            return (
              <FormGroup row key={topic.id}>
              <Grid container  spacing={2}>
              <Grid item xl={9} md={9} sm={9} xs={9} >
                <RhfListOfValuesSelect
                  name={`topics[${i}].id`}
                  defaultValue={topic.id}
                  label="course.topic"
                  group={GROUP.topic}
                  control={methods.control}
                  fullWidth
                />
              </Grid>
              <Grid item xl={3} md={3} sm={3} xs={3} >
              <Grid
                  container
                  direction="column"
                  alignItems="center"
                >

                {topicFields.length !== 1 && (
                  <Grid item>
                  <Button
                    onClick={() => topicRemove(i)}
                    title={intl.formatMessage({
                      id: "course.topic.delete",
                    })}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                  </Button>
                  </Grid>
                )}
                {topicFields.length - 1 === i && (
                  <Grid item>
                  <Button
                    onClick={() => topicAppend({ id: "" , code: "" })}
                    title={intl.formatMessage({
                      id: "course.topic.add",
                    })}
                    color="primary"
                  >
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                  </Button>
                  </Grid>
                )}
                </Grid>
                </Grid>
              </Grid>
              </FormGroup>
            );
          })}
        </FormControl>
      </Grid>
      <Grid item xl={8} md={6} sm={12} xs={12}>
        <RhfTextField
          label="course.keywords"
          name="keywords"
          className={classes.textField}
          control={methods.control}
          multiline={true}
          rows={2}
          id="course-keywords-text"
        />
      </Grid>
      <Grid item xs={12}>
          <FormLabel component="legend">
            {intl.formatMessage({ id: "course.teachers" })}
          </FormLabel>
          <MultiTeacherSelect
            control={methods.control}
            name="teachers"
            classes={classes}
            register={methods.register}
            disabled={DISABLED_CONDITION["course.teacher"].disabled(courseType)}
            errors={errors?.teachers}
            getValues={methods.getValues}
            setValue={methods.setValue}
            reset={methods.reset}
          />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfTextField
          label="course.academic.email"
          name="academicEmail"
          className={classes.textField}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.academicEmail"].disabled(
            courseType
          )}
          id="course-academic-email-text"
          error={errors ? errors.academicEmail : null}
        />
      </Grid>
      <Grid item xl={3} md={4} sm={12} xs={12}>
        <RhfTextField
          label="course.help.desk.email"
          name="helpDeskEmail"
          className={classes.textField}
          control={methods.control}
          disabled={DISABLED_CONDITION["course.helpDeskEmail"].disabled(
            courseType
          )}
          id="course-help-desk-email-text"
          error={errors ? errors.helpDeskEmail : null}
        />
      </Grid>
    </Grid>
  );
};

export default injectIntl(AddEditCourse);
