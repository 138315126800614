import React from "react";


import { injectIntl, useIntl } from "gatsby-plugin-intl";

import { Link, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Seo from "components/common/Seo";
import Layout from 'components/layout/Layout';
import logService from "../../../services/api/logService";
import { navigate } from "gatsby";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";


const EnrollmentLogList = ({ batchId, intl }) => {
    const columns = [
        {
            field: "filename",
            headerName: intl.formatMessage({id: "backoffice.batchLog.filename"}),
            minWidth:100,
            flex: 2,
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            field: "errorComment",
            headerName: intl.formatMessage({id: "backoffice.batchLog.error"}),
            flex: 6,
            renderCell: (params) => {
                return params.value;
            }
        }
    ];

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadServerRows = React.useCallback(async () => {
        setLoading(true);
        const data = await logService.getBatchLogByBatchId(batchId);
        setRows(!!data.data ? data.data : []);
        setLoading(false);
    }, []);



    function CustomNoResultOverlay() {
        const intl = useIntl();
        return (
            <div className="MuiDataGrid-overlay">
                <Typography align="center">
                    {intl.formatMessage({ id: "common.label.no.result" })}
                </Typography>
            </div>
        );
    }

    React.useEffect(() => {
        loadServerRows();
        setRows([]);
    }, [loadServerRows]);

    return (
        <LayoutBo>

            <Seo
                title={intl.formatMessage({ id: "backoffice.batchLog.title" })}
                lang={intl.locale}
            />
            <Link
                component="button"
                variant="h6"
                onClick={() => { navigate(-1) }} >
                {intl.formatMessage({ id: "backoffice.back" })}
            </Link>
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowsPerPageOptions={[]}
                    components={{
                        NoRowsOverlay: CustomNoResultOverlay
                    }}
                    getRowId={(row) => row.batchLogId}
                    getRowHeight={() => 'auto'}
                    hideFooter={true}
                />
            </div>
        </LayoutBo>

    );
};

export default injectIntl(EnrollmentLogList);
