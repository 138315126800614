import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import { DataGrid, useGridApiContext, useGridState } from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';

import {faEdit} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { FormattedMessage, injectIntl, navigate, useIntl,} from "gatsby-plugin-intl";

function DeleteConfirmationDialog(props) {  
    const { open, onClose, onCancel, onDelete } = props;
    const intl = useIntl();
    return (
      <div>
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({id: "customDataGrid.deleteDialog.title"})}
          </DialogTitle>
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({id: "customDataGrid.deleteDialog.contentText"})}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel} autoFocus>{intl.formatMessage({id: "customDataGrid.deleteDialog.cancelButton"})}</Button>
            <Button onClick={onDelete}>{intl.formatMessage({id: "customDataGrid.deleteDialog.deleteButton"})}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

const CustomDatagridActions = (props) => {
    const { onDelete, onAdd, addMessage, selection } = props;
    const intl = useIntl();
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickDelete = () => {
        setOpenDialog(true);
    }

    const handleDeleteDialog = () => {
        onDelete(selection);
        setOpenDialog(false);
    }

    const handleCancelDialog = () => {
        setOpenDialog(false);    
    }
    
    const handleCloseDialog = () => {
        setOpenDialog(false);    
    }

    return (
        <div style={{ display:"flex", gap: "0px 10px", width:"100%", margin:"1rem", padding:"0rem"
        }}>
            {selection?.length > 0 ? 
                <Typography
                color="inherit"
                variant="subtitle1"
                component="span"
                sx={{width:"240px"}}
            >
                {selection?.length}{" "}
                <FormattedMessage
                    id="row.selected"
                    values={{count: selection?.length}}
                ></FormattedMessage>
            </Typography>
                : <></>
            }
            
            {selection?.length > 0 ? 
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickDelete}
                       
                    >
                        {intl.formatMessage({id: "common.delete"})}
                    </Button>
                </>
             : 
                <></>
            }
            
            { addMessage && 
                <div style={{ display:"flex", justifyContent:"end", width:"100%", margin:"0rem", padding:"0rem"}}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ onAdd }               
                    >
                        {intl.formatMessage({id: addMessage})}
                    </Button>
                </div>
            }
            
            <DeleteConfirmationDialog open={openDialog} onDelete={handleDeleteDialog} 
                    onClose={handleCloseDialog} onCancel={handleCancelDialog}/>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
    },
});

function CustomPagination() {
    const apiRef = useGridApiContext();
    const [state] = useGridState(apiRef);
    const classes = useStyles();

    return (
        <Pagination
            className={classes.root}
            color="primary"
            count={state.pagination.pageCount}
            page={state.pagination.page + 1}
            onChange={(event, value) => {
                apiRef.current.setPage(value - 1);
            }}
        />
    );
}

function CustomNoResultOverlay() {
    const intl = useIntl();
    return (
        <div className="MuiDataGrid-overlay">
            <Typography align="center">
                {intl.formatMessage({id: "common.label.no.result"})}
            </Typography>
        </div>
    );
}

const CustomDataGrid = ({
                            columns,
                            totalRows,
                            rows,
                            loading,
                            pageSize = 50,
                            getRowId = null,
                            loadServerRows,
                            initialState={},
                            onDelete,
                            selectionModel,
                            changeSelection,
                            rowDoubleClickLink,
                            editColumnParams={},
                            addMessage = null,
                            addLink = null,
                            intl,
                            page
                        }) => {

    let editColumn = null;
    if(editColumnParams){
        editColumn = {
            key: columns.length+1,
            flex: 0.2,
            field: editColumnParams.field,
            headerName: " ",
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const id = params.value;
                return (
                    <IconButton
                        aria-label={editColumnParams.ariaLabel}
                        color="primary"
                        size="small"
                        disabled={!params.row.editable}
                        onClick={() => {
                            navigate(`${editColumnParams.url}/${id}`);
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                    </IconButton>
                );
            },
        }  
    }                                             
    
    const [sortModel, setSortModel] = React.useState([{}]);

    const handleSortModelChange = (params) => {
        if (JSON.stringify(params) !== JSON.stringify(sortModel)) {
            setSortModel(params);
            loadServerRows(page, params);
        }
    };

    const handlePageChange = (params) => {
        loadServerRows(params, sortModel);
    };

    const handleSelection = (selectionModel) => {
        changeSelection(selectionModel);
    };

    const handleAdd = () => {
        navigate(addLink);
    };

    return (
        <div style={{justifyItems:"center", width:"100%", paddingTop:"0.5rem"}}>
            <div style={{justifyItems:"center", width:"100%", margin:"0rem", padding:"0rem"}}>
                <CustomDatagridActions onDelete={onDelete} onAdd={handleAdd} addMessage={addMessage} selection={selectionModel}/>
                <div style={{justifyItems:"center", width:"100%", margin:"0rem", padding:"0rem"}}>
                    <DataGrid
                        rows={rows}
                        initialState={initialState}
                        checkboxSelection
                        pagination
                        autoHeight
                        getRowId={(rows)=> { return rows.id;}}
                        columns={ editColumn ? [...columns, editColumn] : columns }
                        loading={loading}
                        pageSize={pageSize}
                        rowCount={totalRows}
                        rowsPerPageOptions={[pageSize]}
                        onPageChange={handlePageChange}
                        
                        sortingMode="server"
                        paginationMode="server"
                        //sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        
                        onSelectionModelChange={(newSelection) => {
                            handleSelection(newSelection);
                        }}
                        selectionModel={selectionModel}
                        components={{
                            //Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoResultOverlay
                        }}
                        hideFooterSelectedRowCount={ false }
                        isRowSelectable={(params) => params.row && params.row.deletable }
                        onRowDoubleClick={
                            (event) => {
                                if(event?.row?.editable){
                                    navigate(`${rowDoubleClickLink}/${event.id}`);
                                } 
                            }
                        }
                        rowHeight={25}
                        headerHeight={35}
                    />
                </div>
            </div>
        </div>
    );
};

export default injectIntl(CustomDataGrid);
