import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchListOfValuesForceRetrieve, GROUP_STATE } from "state/common/listOfValueSlice";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useIntl } from "gatsby-plugin-intl";
import { Controller } from "react-hook-form";


const filter = createFilterOptions();

const RhfAutoCompleteListOfValuesMultiChoice = ({
    group,
    name,
    inputLabel,
    control,
    id,
    setValue,
    getValues,
    addOption = false,
    sxAutoComplete,
    sxInput
}) => {

    const selector = GROUP_STATE[group].state;
    const valuesList = useSelector(selector);
    const intl = useIntl();
    const [selectedItems, setSelectedItems] = useState([]);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" sx={{ verticalAlign: "bottom", height: 20, width: 20, }} />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [dialogValue, setDialogValue] = React.useState({
        label: '',
        langCode: 'EN'
      });
    const [open, toggleOpen] = React.useState(false);

    const dispatch = useDispatch();
    React.useEffect(() => {
        let mounted = true;
        async function fetchData() {
            try {
                if (mounted) {
                    dispatch(fetchListOfValuesForceRetrieve(group));
                } 
            } catch (error) {
            }
        }
        fetchData();
        return () => (mounted = false);
    }, []);

    React.useEffect(() => {
        setValue(name, selectedItems);
    }, [selectedItems]);

    function methodEqual(option, value) {
        return option.value === value.value
    }

    function handleOnChange(_event, selected, reason, value) {
        if(value && value.option && value.option.isAddButton){
            handleSelectOption(value, selected);
            //dispatch(updateListOfValues(value));
        } else if (reason === 'removeOption') {
            setSelectedItems(selected);
        } else if (reason === 'clear') {
            setSelectedItems([]);
        } else if(reason === 'selectOption'){
            handleSelectOption(value, selected);
        };
    }

    function handleSelectOption(newValue, selected) {
        if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
                toggleOpen(true);
                setDialogValue({
                    label: newValue,
                    langCode: 'EN'
                });
            });
        } else if (newValue.option && newValue.option.inputValue) {
            toggleOpen(true);
            setDialogValue({
                label: newValue.option.inputValue,
                langCode: 'EN'  
            });
        } else {
            setSelectedItems(selected);
        }   
    }

    const handleClose = () => {
        setDialogValue({
            label: '',
            langCode: 'EN'
        });
        toggleOpen(false);
    };

    const handleAddOption = () => {
        const newItem = {
            typeCode: group,
            label: dialogValue.label,
            langCode: dialogValue.langCode
        };
        let itemList = getValues(name);
        itemList.push(newItem);
        setSelectedItems(itemList);
        handleClose();
    };

    return (
            <Controller
                name={name}
                control={control}
                render={({ field: 
                
                { value } }) => (
                    <div>
                    <Autocomplete
                        multiple
                        autoComplete
                        autoHighlight
                        filterSelectedOptions={false}
                        disableCloseOnSelect
                        id={id}
                        options={valuesList}
                        sx = { sxAutoComplete }
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.label;
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                {!option.isAddButton &&
                                    <>        
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}     
                                    </>
                                }
                                {option.isAddButton && <>{option.label}</>}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} 
                            label={intl.formatMessage({ id: inputLabel })} 
                            placeholder={intl.formatMessage({ id: inputLabel })} 
                            variant="standard" 
                            sx={sxInput}
                            />
                        )}
                        value={value}

                        onChange={(event, selected, reason, value, newValue) => {
                            handleOnChange(event, selected, reason, value);
                        }}
                        filterOptions={(options, params) => {
                            const { inputValue } = params;
                            const filtered = filter(options, params);
                            const isExisting = options.some((option) => !!inputValue && !!option.label && (inputValue.toUpperCase().replace(' ', '') === option.label.toUpperCase().replace(' ', '')));
                            if (addOption && inputValue !== '' && !isExisting) {
                                const label = intl.formatMessage({ id: "common.dialog.add.dynlov.item.add" }) + ' "' + params.inputValue + '"';
                                const newOption = {
                                    isAddButton: true,
                                    inputValue: params.inputValue,
                                    label: label,
                                };
                                filtered.push(newOption);
                            }
                            return filtered;
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return methodEqual(option, value)
                        }}
                    />
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>
                            {intl.formatMessage({ id: "common.dialog.add.dynlov.item.title" })} {intl.formatMessage({ id: inputLabel })} ? 
                        </DialogTitle>
                        <DialogContent>
                            <Typography component="p" variant="body2" align='justify' style={{ whiteSpace: 'pre-line' }}>
                                {intl.formatMessage({ id: "common.dialog.add.dynlov.item.content" })}
                            </Typography>
                            <TextField autoFocus margin="dense" type="text" variant="standard" required={true}
                                value={dialogValue.label}
                                sx = {{mt: 4}}
                                onChange={(event) =>
                                    setDialogValue({
                                    ...dialogValue,
                                    label: event.target.value,
                                    })
                                }
                                label={intl.formatMessage({ id: "common.dialog.add.dynlov.item.text" })}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>{intl.formatMessage({ id: "common.dialog.add.dynlov.item.cancel" })}</Button>
                            <Button onClick={handleAddOption}>{intl.formatMessage({ id: "common.dialog.add.dynlov.item.add" })}</Button>
                        </DialogActions>
                    </Dialog>
                    </div>
                )}
            />        
    );
};

export {
    RhfAutoCompleteListOfValuesMultiChoice
};


