import React from "react";
import Stack from "@mui/material/Stack";
import { injectIntl } from "gatsby-plugin-intl";
import {GROUP} from "utils/constants/back-office-constants";
import { RhfAutoCompleteListOfValuesMultiChoice } from "ui/RhfAutoCompleteListOfValuesMultiChoice";

const defaultStyles = {
    rowFormStack : {
        marginLeft : 4,
        marginTop : 2,
        spacing : 6,
        justifyContent: "center",
        alignItems: "center"
    },
    listOfValuesClass: {
        justifyContent: "center",
        width: "30ch",
    },
}; 


const AddEditMultiValuesBloc = ({intl, id, useFormHook, rowFormStack = defaultStyles.rowFormStack }) => {

return (
<Stack id={id} direction="column" marginTop={rowFormStack.marginTop}>  

        <Stack id="form-row-3-multivalues-row-1" direction="row" 
              spacing={rowFormStack.spacing} 
              marginLeft={rowFormStack.marginLeft} 
              marginTop={rowFormStack.marginTop}
              justifyContent={rowFormStack.justifyContent}
              alignItems={rowFormStack.alignItems}> 
            
            <RhfAutoCompleteListOfValuesMultiChoice
                addOption={true}
                name={"academicTitles"}
                inputLabel={"academic.academicTitles"}
                group={GROUP.academicTitle}
                control={useFormHook.control}
                setValue={useFormHook.setValue}
                getValues={useFormHook.getValues}
                sxInput={defaultStyles.listOfValuesClass}
                sxAutoComplete={defaultStyles.listOfValuesClass}              
                id="create-academic-academicTitles"/>
                
            <RhfAutoCompleteListOfValuesMultiChoice
              addOption={true}
              name={"academicDisciplines"}
              inputLabel={"academic.academicDisciplines"}
              group={GROUP.academicDiscipline}
              control={useFormHook.control}
              setValue={useFormHook.setValue}
              getValues={useFormHook.getValues}
              sxInput={defaultStyles.listOfValuesClass}
              sxAutoComplete={defaultStyles.listOfValuesClass}         
              id="create-academic-academicDisciplines"/>

            <RhfAutoCompleteListOfValuesMultiChoice
                addOption={true}
                name={"expertiseFields"}
                inputLabel={"academic.expertiseFields"}
                group={GROUP.expertiseField}
                control={useFormHook.control}
                setValue={useFormHook.setValue}
                getValues={useFormHook.getValues}
                sxInput={defaultStyles.listOfValuesClass}
                sxAutoComplete={defaultStyles.listOfValuesClass}      
                id="create-academic-expertiseFields"/> 
        
        </Stack>  

       <Stack id="form-row-4-multivalues-row-2" direction="row" 
              spacing={rowFormStack.spacing} 
              marginLeft={rowFormStack.marginLeft} 
              marginTop={rowFormStack.marginTop}
              justifyContent={rowFormStack.justifyContent}
              alignItems={rowFormStack.alignItems}>       

            <RhfAutoCompleteListOfValuesMultiChoice
                addOption={true}
                name={"workingLanguages"}
                inputLabel={"academic.workingLanguages"}
                group={GROUP.workingLanguage}
                control={useFormHook.control}
                setValue={useFormHook.setValue}
                getValues={useFormHook.getValues}
                sxInput={defaultStyles.listOfValuesClass}
                sxAutoComplete={defaultStyles.listOfValuesClass}       
                id="create-academic-workingLanguages"/>

            <RhfAutoCompleteListOfValuesMultiChoice
                addOption={true}
                name={"researchCountries"}
                inputLabel={"academic.researchCountries"}
                group={GROUP.researchCountry}
                control={useFormHook.control}
                setValue={useFormHook.setValue}
                getValues={useFormHook.getValues}
                sxInput={defaultStyles.listOfValuesClass}
                sxAutoComplete={defaultStyles.listOfValuesClass}       
                id="create-academic-researchCountries"/>
            
            <RhfAutoCompleteListOfValuesMultiChoice
                addOption={true}
                name={"researchProjects"}
                inputLabel={"academic.researchProjects"}
                group={GROUP.researchProject}
                control={useFormHook.control}
                setValue={useFormHook.setValue}
                getValues={useFormHook.getValues}
                sxInput={defaultStyles.listOfValuesClass}
                sxAutoComplete={defaultStyles.listOfValuesClass}       
                id="create-academic-researchProjects"/>
        </Stack>  
        
</Stack>
)}




export default injectIntl(AddEditMultiValuesBloc);
