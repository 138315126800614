import React from "react";
import Stack from "@mui/material/Stack";
import { injectIntl } from "gatsby-plugin-intl";
import { RhfTextField } from 'components/backoffice/common/RhfFields';


const defaultStyles = {
    rowFormStack : {
        marginLeft : 4,
        marginTop : 2,
        spacing : 6,
        justifyContent: "center",
        alignItems: "center"
    },
    textFieldLinkSx : { 
        justifyContent: "center", 
        width: "51ch"
    }
}; 


const AddEditInfosBloc = ({intl, id, useFormHook, rowFormStack = defaultStyles.rowFormStack }) => {


 return (    
    <Stack id={id} direction="column"
                marginTop={rowFormStack.marginTop}>  

        <Stack id="info-row-1" direction="row" 
                spacing={rowFormStack.spacing}
                marginTop={rowFormStack.marginTop}
                justifyContent={defaultStyles.rowFormStack.justifyContent}
                alignItems={rowFormStack.alignItems}>  

            <RhfTextField
                label="academic.link.publication.webpage"
                name="links.linkPublicationWebPage"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>

            <RhfTextField
                label="academic.link.publication.feedlink"
                name="links.linkPublicationFeedLink"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Stack>

        <Stack id="info-row-2" direction="row" 
                spacing={rowFormStack.spacing}
                marginTop={rowFormStack.marginTop}
                justifyContent={rowFormStack.justifyContent}
                alignItems={rowFormStack.alignItems}>   
            
            <RhfTextField
                label="academic.link.pro.webpage"
                name="links.linkProWebPage"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>

            <RhfTextField
                label="academic.link.perso.webpage"
                name="links.linkPersoWebPage"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Stack>

        <Stack id="info-row-3" direction="row" 
                spacing={rowFormStack.spacing}
                marginTop={rowFormStack.marginTop}
                justifyContent={rowFormStack.justifyContent}
                alignItems={rowFormStack.alignItems}>  

            <RhfTextField
                label="academic.link.googleScholar"
                name="links.linkGoogleScholar"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>

            <RhfTextField
                label="academic.link.researchGate.profile"
                name="links.linkResearchGateProfile"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Stack>

        <Stack id="info-row-4" direction="row"
                spacing={11}
                marginTop={rowFormStack.marginTop}
                justifyContent={rowFormStack.justifyContent}
                alignItems={rowFormStack.alignItems}> 

            <RhfTextField
                label="academic.link.orcid"
                name="links.linkOrcid"
                required={false}
                sx={{ justifyContent: "center", width: "18ch"}}
                control={useFormHook.control}>
            </RhfTextField>

            <RhfTextField
                label="academic.link.twitter"
                name="links.linkTwitter"
                required={false}
                sx={{ justifyContent: "center", width: "25ch"}}
                control={useFormHook.control}>
            </RhfTextField>  

            <RhfTextField
                label="academic.link.linkedIn"
                name="links.linkLinkedIn"
                required={false}
                sx={{ justifyContent: "center", width: "51ch"}}
                control={useFormHook.control}>
            </RhfTextField>          
        </Stack>

    </Stack>       
    )
}
export default injectIntl(AddEditInfosBloc);
