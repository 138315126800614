import React, {Fragment, useRef} from 'react';

import {Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Tab, Tabs, Typography} from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

import {useBeforeunload} from 'react-beforeunload';
import {FormProvider, useForm} from 'react-hook-form';
import {faArrowLeft, faArrowRight, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {yupResolver} from '@hookform/resolvers/yup';
import {injectIntl, navigate, useIntl} from 'gatsby-plugin-intl';

import {getUserRole} from 'services/auth';
import courseService from 'services/api/courseService';
import {ROLE} from 'utils/constants/constants';
import {courseSchema, DISABLED_CONDITION, sessionSchema} from 'utils/courses-schema';

import Seo from 'components/common/Seo';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import AddEditCourse from 'components/backoffice/catalog/AddEditCourse';
import AddEditCourseSession from 'components/backoffice/catalog/AddEditCourseSession';
import LayoutBo from "components/backoffice/common/layout/LayoutBo";

function DuplicateConfirmDialog(props) {  
    const { open, onClose, onCancel, onDuplicate } = props;
    const intl = useIntl();
    return (
      <div>
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({id: "course.duplicateConfirmDialog.title"})}
          </DialogTitle>
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({id: "course.duplicateConfirmDialog.text"})}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel} autoFocus>{intl.formatMessage({id: "course.duplicateConfirmDialog.cancel"})}</Button>
            <Button onClick={onDuplicate}>{intl.formatMessage({id: "course.duplicateConfirmDialog.duplicate"})}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function DuplicateInfoDialog(props) {  
    const { open, dialogTitle, dialogContentText, onClose, onOk} = props;
    const intl = useIntl();
    return (
      <div>
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    whiteSpace:  'pre-wrap',
                },
             }}
        >
          <DialogTitle id="alert-dialog-title">
            { dialogTitle }
          </DialogTitle>
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                { dialogContentText }
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={onOk} autoFocus> { intl.formatMessage({id: "course.duplicateInfoDialog.ok"}) } </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }



const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div 
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}



const CourseSubmitSection = ({
                                 userRole,
                                 approved,
                                 submitForm,
                                 handleApprove,
                                 disabled,
                                 displayApprove,
                                 isModified,
                             }) => {
    const intl = useIntl();

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                style={styles.marginLeft}
                onClick={submitForm}
                disabled={disabled}
            >
                {intl.formatMessage({id: "course.save"})}
            </Button>
            
            <ConfirmationDialog
                isModified={isModified}
                listingUrl="/back-office/courses"
            />
        </Fragment>
    );
};
const ApproveBtn = ( {role, courseId, handleApprove, disableSubmit, approved}) => {
    const intl = useIntl();
    let isVisible = (!!role && role.some((r) => [ROLE.admin, ROLE.admin_university, ROLE.courses_approver].includes(r)) && !!courseId) ? true : false;
    
    return isVisible ? (
            <Button
                variant="outlined"
                color="primary"
                onClick={handleApprove}
                style={styles.marginLeft}
                disabled={disableSubmit}
            >
                {approved
                    ? intl.formatMessage({id: "course.disapprove"})
                    : intl.formatMessage({id: "course.approve"})}
            </Button>
        )
    : null;
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    textField: {
        marginRight: theme.spacing(8),
        marginTop: theme.spacing(1),
        width: "60%",
    },
    [theme.breakpoints.down('md')]: {
      textField: {
          marginRight: theme.spacing(6),
          marginTop: theme.spacing(1),
          width: "90%",
      },
    },
    textFieldLarge: {
        marginRight: theme.spacing(8),
        marginTop: theme.spacing(1),
        width: "80%",
    },
    [theme.breakpoints.down('lg')]: {
        textFieldLarge: {
            marginRight: theme.spacing(8),
            marginTop: theme.spacing(1),
            width: "90%",
        },
    },
    formControlLabel: {
        verticalAlign: "bottom",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(8),
    },
    smallInput: {
        width: "8ch",
        marginRight: theme.spacing(4),
    },
    mediumInput: {
        width: "15ch",
        marginRight: theme.spacing(4),
    },
    marginLeft: {
        marginLeft: theme.spacing(3),
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    card: {
        maxHeight: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
        backgroundColor: theme.palette.background.paper,
    },
    cardHeader: {
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    draggable:{
        cursor:"move"
    }
}));


const styles = {
    marginLeft: {
        marginLeft: '2em'
    }
}

const CourseAddEditPage = ({intl, courseId}) => {
        const classes = useStyles();
        const theme = useTheme();
        const [tabPanelValue, setTabPanelValue] = React.useState(0);
        const role = getUserRole();
        const [openDuplicateDialog, setOpenDuplicateDialog] = React.useState(false);
        const [openInformationDialog, setOpenInformationDialog] = React.useState(false);
        const [duplicate, setDuplicate] = React.useState(false);
        const [loading, setLoading] = React.useState(false);

        
        const handleClickDuplicate = () => {
            setOpenDuplicateDialog(true);
        }

        const handleCloseDuplicateDialog = () => {
            setOpenDuplicateDialog(false);
        }

        const handleCloseInformationDialog = () => {
            setDuplicate(false);
            setOpenInformationDialog(false);
        }

        const getUID = () => {
            return Date.now().toString(36);
        }

        const handleDuplicateCourse = async () => {
            setOpenDuplicateDialog(false);
            setLoading(true);
            setDisableSubmit(true);
            const response = await courseService.searchById(courseId);
            let course = response.data;
            if(course){
                course.approved=false;
                course.visible=false;
                if(course.key){
                    course.key = course.key+"-"+getUID();
                }
                course.title='[DUPLICATE] '+course.title;

                if(course.links){
                    course.links=course.links.map(link => {
                        const container = link;
                        container.linkId = null;
                        return container;
                    });    
                }
            
                if(course.sessions){
                    course.sessions=course.sessions.map(session => {
                        const container = session;
                        container.sessionId = null

                        if(session.key){
                            container.key= session.key+"-"+getUID();
                        }
                        
                        if(course.links){
                            session.links=session.links.map(link => {
                                const container = link;
                                container.linkId = null;
                                return container;
                            });
                        }

                        if(course.teachers){
                            session.teachers=session.teachers.map(teacher => {
                                const container = teacher;
                                container.teacherId = null;
                                return container;
                            });
                        }
                        
                        return container;
                    });
                }
            }
            courseService.saveCourse(course)
            .then((response) => {
                setLoading(false);
                setDisableSubmit(false);
                setOpenDuplicateDialog(false);
                const duplicateCourseId = response.data.id;
                setDuplicate(true);
                navigate('/back-office/courses/edit/'+duplicateCourseId);
            })
            .catch((error) => {
                setOpenDuplicateDialog(false);
                setDisableSubmit(false);
                let messageErreur;
                if( isPermissionError(error.status)){
                    messageErreur ="course.error.permission";
                }else{
                    messageErreur = (error && error.data && error.data.errorCode
                        ? error.data.errorCode
                        : "course.update.error"
                    );
                }
                setSubmitMessage(messageErreur);
                setErrorParameters(error?.data?.parameters);
                setOpenError(true);
                setLoading(false);
            });
            
        };

        React.useEffect(
            () => {
                if(duplicate){
                    setOpenInformationDialog(true);
                }
            }
        );


        /**
         * Return true if the cause of the error is a the permission (401, 403)
         * @param {*} errorCode 
         * @returns 
         */
        function isPermissionError(errorCode){
            let errorPermission = false
            if(401 == errorCode || 403 == errorCode){
                errorPermission = true;
            }
    
            return errorPermission;
        }

        const defaultValues = React.useMemo(() => {
            return {
                university: "",
                logo: "UNIVERSITY",
                type: "",
                subtype: "",
                code: "",
                period: "",
                engageCourse: false,
                courseLanguage: "",
                key: "",
                privateCourse: true,
                visible: false,
                leader: true,
                deadlineRegistration: null,
                openToRegistration: false,
                multiJoinCode: "",
                maxRegistration: "",
                title: "",
                shortDescription: "",
                longDescription: "",
                language: "",
                keywords: "",
                academicEmail: "",
                helpDeskEmail: "",
                cycles: [],
                links: [{description: "", type: "", url: ""}],
                topics: [{id: ""}],
                teachers: [
                    {
                        gender: "",
                        firstname: "",
                        lastname: "",
                        email: "",
                        title: "",
                        university: "",
                        remoteKey: "",
                    },
                ],
                sessions: [
                    {
                        sessionId: "",
                        key: "",
                        format: "",
                        language: "",
                        title: "",
                        startDate: null,
                        endDate: null,
                        links: [{description: "", type: "", url: ""}],
                        teachers: [
                            {
                                gender: "",
                                firstname: "",
                                lastname: "",
                                email: "",
                                title: "",
                                university: "",
                                remoteKey: "",
                            },
                        ],
                    },
                ],
            };
        }, []);
        const useCourseSchema = React.useRef(true);
        const methods = useForm(
            {
                resolver: useCourseSchema.current
                    ? yupResolver(courseSchema)
                    : yupResolver(sessionSchema),
                defaultValues: defaultValues,
                mode: "onChange",
            }
        );

        const {
            trigger,
            reset,
            formState: {isDirty, errors},
        } = methods;

        const courseType = methods.watch("type");

        const [openSuccess, setOpenSuccess] = React.useState(false);
        const [openError, setOpenError] = React.useState(false);
        const [approved, setApproved] = React.useState(false);
        const [disableSubmit, setDisableSubmit] = React.useState(false);
        const [submitMessage, setSubmitMessage] = React.useState("");
        const [errorParameters, setErrorParameters] = React.useState("");

        React.useEffect(() => {
            const fetchData = async () => {
                try {
                    const result = await courseService.searchById(courseId);
                    const data = result.data;
                    setApproved(data.approved);
                    if (!!data.teachers) {
                        updateTeacherInitValues(data.teachers);
                    }
                    if (!!data.sessions) {
                        data.sessions.forEach((session) => {
                            if (!!!session.startDate) {
                                session.startDate = null;
                            }
                            if (!!!session.endDate) {
                                session.endDate = null;
                            }
                            if (!session.links || session.links.length === 0) {
                                session.links = [{description: "", type: "", url: ""}];
                            }
                            if (!session.teachers || session.teachers.length === 0) {
                                session.teachers = [
                                    {
                                        gender: "",
                                        firstname: "",
                                        lastname: "",
                                        email: "",
                                        title: "",
                                        university: "",
                                        remoteKye: "",
                                    },
                                ];
                            } else {
                                updateTeacherInitValues(session.teachers);
                            }
                        });
                    }
                    const newDefaultValues = Object.assign({}, defaultValues, data);
                    reset(newDefaultValues);
                } catch (error) {
                    navigate("/back-office/courses");
                }
            };

            const updateTeacherInitValues = (teachers) => {
                teachers.forEach((teacher) => {
                    const currentTeacherKey = Object.keys(teacher);
                    Object.keys(defaultValues.teachers[0]).forEach((key) => {
                        if (!currentTeacherKey.includes(key)) {
                            teacher[key] = "";
                        }
                    });
                });
            };

            if (!!courseId) {
                fetchData();
            }
        }, [courseId, reset, defaultValues]);
        
        const goToNextStep = async () => {
            const result = await trigger();
            if (result) {
                useCourseSchema.current = false;
                setTabPanelValue(1);
            }
        };

        


        useBeforeunload((event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = "";
            }
        });

        const validationSchema = {0: true, 1: false};
        const handleChange = async (event, newValue) => {
            const result = await trigger();
            if (result) {
                courseSchema.current = validationSchema[newValue];
                setTabPanelValue(newValue);
            }
        };

        const updateTeacherInitValues = (teachers) => {
            teachers.forEach((teacher) => {
                const currentTeacherKey = Object.keys(teacher);
                Object.keys(defaultValues.teachers[0]).forEach((key) => {
                    if (!currentTeacherKey.includes(key)) {
                        teacher[key] = "";
                    }
                });
            });
        };
        const submitForm = async () => {
            setOpenSuccess(false);
            setOpenError(false);
            const formData = methods.getValues();
            resetDisabledField();
            setDisableSubmit(true);

            // validate form
            const result = await trigger();

            if (result) {
                if (!!courseId) {
                    await courseService
                        .updateCourse(courseId, formData)
                        .then((response) => {
                            setSubmitMessage("course.update.success");
                            setOpenSuccess(true);
                            methods.reset(methods.getValues());
                        })
                        .catch((error) => {
                            let messageErreur;
                            if( isPermissionError(error.status)){
                                messageErreur ="course.error.permission";
                            }else{
                                messageErreur = (error && error.data && error.data.errorCode
                                    ? error.data.errorCode
                                    : "course.update.error"
                                );
                            }
                            setSubmitMessage(messageErreur);
                            setErrorParameters(error?.data?.parameters);
                            setOpenError(true);
                        });
                } else {
                    await courseService
                        .saveCourse(formData)
                        .then((result) => {
                            courseId = result.data.id;
                            setSubmitMessage("course.create.success");
                            setOpenSuccess(true);
                            const data = result.data;
                            if (!!data.teachers) {
                                updateTeacherInitValues(data.teachers);
                            }

                            if (!!data.sessions) {
                                data.sessions.forEach((session) => {
                                    if (!!!session.startDate) {
                                        session.startDate = null;
                                    }
                                    if (!!!session.endDate) {
                                        session.endDate = null;
                                    }
                                    if (!session.links || session.links.length === 0) {
                                        session.links = [{description: "", type: "", url: ""}];
                                    }
                                    if (!session.teachers || session.teachers.length === 0) {
                                        session.teachers = [
                                            {
                                                gender: "",
                                                firstname: "",
                                                lastname: "",
                                                email: "",
                                                title: "",
                                                university: "",
                                                remoteKye: "",
                                            },
                                        ];
                                    } else {
                                        updateTeacherInitValues(session.teachers);
                                    }
                                });
                            }

                            const newDefaultValues = Object.assign({}, defaultValues, data);
                            methods.reset(newDefaultValues);
                        })
                        .catch((error) => {
                            let messageErreur;
                            if( isPermissionError(error.status)){
                                messageErreur ="course.error.permission";
                            }else{
                                messageErreur = (error && error.data && error.data.errorCode
                                    ? error.data.errorCode
                                    : "course.create.error") ;
                            }
                            setSubmitMessage(messageErreur);
                            setErrorParameters(error?.data?.parameters);
                            setOpenError(true);
                        });
                }
            }
            setDisableSubmit(false);
        };

        const handleApprove = () => {
            setDisableSubmit(true);
            if (approved) {
                courseService.disapproveCourse(courseId).then(() => {
                    setSubmitMessage("course.disapprove.success");
                    setApproved(false);
                    setOpenSuccess(true);
                });
            } else {
                courseService.approveCourse(courseId).then(() => {
                    setSubmitMessage("course.approve.success");
                    setApproved(true);
                    setOpenSuccess(true);
                });
            }
            setDisableSubmit(false);
        };

        const fielsNameAndCondition = [
            {
                condition: "course.registrationDeadline",
                field: "deadlineRegistration",
                setDefaultValueNull: true,
            },
            {condition: "session.teachers", field: "session.teachers", setDefaultArrayValue: true},
            {condition: "course.subtype", field: "subtype"},
            {condition: "course.code", field: "code"},
            {
                condition: "course.engageCourse",
                field: "engageCourse",
                setDefaultValueBoolean: true,
            },
            {condition: "course.pkey", field: "key"},
            // {condition: "course.multiJoinCode", field: "multiJoinCode"},
            // {
            //     condition: "course.leader",
            //     field: "leader",
            //     setDefaultValueBoolean: true,
            // },
            {
                condition: "course.openToRegistration",
                field: "openToRegistration",
                setDefaultValueBoolean: true,
            },
            {condition: "course.maxRegistration", field: "maxRegistration"},
            {condition: "course.period", field: "period"},
            {condition: "course.teacher", field: "teachers", setDefaultArrayValue: true},
            {condition: "course.academicEmail", field: "academicEmail"},
            {condition: "course.helpDeskEmail", field: "helpDeskEmail"},
        ];

        const resetDisabledField = () => {
            //prevent disabled fields from being sending on submit
            fielsNameAndCondition.forEach((item) => {
                if (DISABLED_CONDITION[item.condition].disabled(courseType)) {
                    if (item.setDefaultValueNull) {
                        methods.setValue(item.field, null);
                    } else if (item.setDefaultValueBoolean) {
                        methods.setValue(item.field, false);
                    } else if (item.setDefaultArrayValue) {
                        methods.setValue(item.field, []);
                    } else {
                        methods.setValue(item.field, "");
                    }
                }
            });
        };

        return (
            <LayoutBo>
                <Seo
                    title={
                        !!courseId
                            ? intl.formatMessage({id: "course.edit"})
                            : intl.formatMessage({id: "course.new"})
                    }
                    lang={intl.locale}
                />
                
                <DuplicateInfoDialog 
                    open={openInformationDialog} 
                    onClose={handleCloseInformationDialog}
                    onOk={handleCloseInformationDialog}
                    dialogTitle={ intl.formatMessage({id: "course.duplicateInfoDialog.title"}) }
                    dialogContentText={ intl.formatMessage({id: "course.duplicateInfoDialog.text"}) }
                />

                <Card className={classes.card}>
                    <CardHeader component="h1" className={classes.cardHeader}
                                title={!!courseId
                                    ? intl.formatMessage({id: "course.edit"})
                                    : intl.formatMessage({id: "course.new"})}
                    />
                    <CardContent className={classes.cardContent}>
                        <div className={classes.root}>
                            <Tabs
                                value={tabPanelValue}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Course" {...a11yProps(0)} />
                                {!DISABLED_CONDITION["session"].disabled(courseType) && (
                                    <Tab label="Session" {...a11yProps(1)} />
                                )}
                            </Tabs>
 
                            <FormProvider {...methods}>
                                <form noValidate>
                                    <TabPanel value={tabPanelValue} index={0} dir={theme.direction}>
                                        <AddEditCourse classes={classes}></AddEditCourse>
                                        <Grid py={2} container justifyContent="center">
                                            <Box py={3}>
                                                {!DISABLED_CONDITION["session"].disabled(courseType) ? (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={styles.marginLeft}
                                                            onClick={goToNextStep}
                                                        >
                                                            {intl.formatMessage({id: "course.to.session"})}
                                                            <FontAwesomeIcon
                                                                className={classes.marginLeft}
                                                                icon={faArrowRight}
                                                            ></FontAwesomeIcon>
                                                        </Button>

                                                        <ConfirmationDialog
                                                            className={classes.marginLeft}
                                                            isModified={isDirty}
                                                            listingUrl="/back-office/courses"
                                                        />
    
                                                        <ApproveBtn role={role} courseId={courseId} handleApprove={handleApprove} disableSubmit={disableSubmit} approved={approved} />

                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            style={styles.marginLeft}
                                                            onClick={handleClickDuplicate}
                                                            disabled={disableSubmit}
                                                        >
                                                            {intl.formatMessage({id: "course.duplicate"})}
                                                        </Button>

                                                        <DuplicateConfirmDialog 
                                                            open={openDuplicateDialog} 
                                                            onDuplicate={handleDuplicateCourse} 
                                                            onClose={handleCloseDuplicateDialog} 
                                                            onCancel={handleCloseDuplicateDialog}
                                                        />


                                                        { loading && 
                                                           <Box sx={{   display: 'flex',    
                                                                        justifyContent: 'center'
                                                                    }}
                                                            >
                                                                <CircularProgress />
                                                            </Box>
                                                        }   
                                                    </>
                                                ) : (
                                                    <>  
                                                        <CourseSubmitSection
                                                            isModified={isDirty}
                                                            userRole={role}
                                                            approved={approved}
                                                            submitForm={submitForm}
                                                            handleApprove={handleApprove}
                                                            disabled={disableSubmit}
                                                            displayApprove={!!courseId}
                                                        />
                                                        <ApproveBtn 
                                                            role={role} 
                                                            courseId={courseId} 
                                                            handleApprove={handleApprove} 
                                                            disableSubmit={disableSubmit} 
                                                            approved={approved} 
                                                        />   
                                                    </>                                                 
                                                )}
                                            </Box>
                                        </Grid>
                                    </TabPanel>
                                    {!DISABLED_CONDITION["session"].disabled(courseType) && (
                                        <TabPanel value={tabPanelValue} index={1} dir={theme.direction}>
                                            <AddEditCourseSession classes={classes} control={methods.control}/>
                                            <Grid container justifyContent="center">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.marginRight}
                                                    onClick={() => {
                                                        useCourseSchema.current = true;
                                                        setTabPanelValue(0);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className={classes.marginRight}
                                                        icon={faArrowLeft}
                                                    ></FontAwesomeIcon>
                                                    {intl.formatMessage({id: "course.to.course"})}
                                                </Button>
                                                <CourseSubmitSection
                                                    isModified={isDirty}
                                                    userRole={role}
                                                    approved={approved}
                                                    submitForm={submitForm}
                                                    handleApprove={handleApprove}
                                                    disabled={disableSubmit}
                                                    displayApprove={!!courseId}
                                                    classes={classes.marginLeft}
                                                />                        
                                            </Grid>
                                        </TabPanel>
                                    )}
                                </form>
                            </FormProvider>
                        </div>
                    </CardContent>
                </Card>
                {openSuccess && (
                    <Collapse in={openSuccess}>
                        <Alert
                            severity="success"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenSuccess(false);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </IconButton>
                            }
                        >
                            {intl.formatMessage({id: submitMessage})}
                        </Alert>
                    </Collapse>
                )}
                {openError && (
                    <Collapse in={openError}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenError(false);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </IconButton>
                            }
                        >
                          <Typography variant="body1" paragraph>
                            {intl.formatMessage({id: submitMessage})} {errorParameters}
                          </Typography>
                        </Alert>
                    </Collapse>
                )}

            </LayoutBo>
        );
    }
;

export default injectIntl(CourseAddEditPage);
