import * as yup from "yup";
import {OPPORTUNITY_TYPE} from "./constants/back-office-constants";

export const FIELD_CONDITION = {
  coverImage: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  hook: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  deadline: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  content: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  link: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
  keyword: {
    disabled: function (type) {
      return !!type && type === OPPORTUNITY_TYPE.alert;
    },
  },
};

export const opportunitySchema = yup.object().shape({
  type: yup.string().required("error.field.required"),
  title: yup.string().required("error.field.required"),
  startDate: yup.date().required("error.field.required"),
  endDate: yup
    .date("error.invalid.date")
    .required("error.field.required")
    .when(
      "startDate",
      (startDate, yup) =>
        startDate && yup.min(startDate, "End time cannot be before start time")
    ),

  //   yup
  //     .date()
  //     .required("error.field.required")
  //     .when('startDate', (st, schema) => {
  //         return yup.date().min(st, "error.end.befor.start");
  //       }),
  publicProfile: yup.array().min(1, "error.field.required"),
  publicCycle: yup.array().when(["publicProfile"], {
    is: (publicProfile) => {return publicProfile.filter((item) => item.code === "STUDENT").length > 0},
    then: yup.array().min(1, "error.field.required"),
  })
  //   code: yup.string().when(["type"], {
  //     is: (type) => DISABLED_CONDITION["course.code"].required(type),
  //     then: yup.string().required("error.field.required"),
  //   }),
});
