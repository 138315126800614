

import React from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "gatsby-plugin-intl";

import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { parseISO } from 'date-fns'
import { dateTime2IsoString } from "utils/date-utils";

const RhfDateTimePicker = ({
    name,
    label,
    defaultValue,
    control,
    format,
    error,
    onChange = null,
    ...props
}) => {
    const intl = useIntl();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <MobileDateTimePicker
                        label={intl.formatMessage({ id: label })}
                        format={format}
                        defaultValue={parseISO(defaultValue)}
                        value={parseISO(field.value)}
                        inputRef={field.ref}
                        onChange={(dateTime) => {
                            field.onChange(dateTime2IsoString(dateTime));
                            if (onChange) onChange();
                        }}
                        slotProps={{
                            textField: {
                                helperText: error ? intl.formatMessage({ id: error.message }) : null,
                            },
                        }}
                        {...props}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default RhfDateTimePicker;