import React from 'react';
import * as layoutStyles from 'styles/backoffice/layout.module.scss';

import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';

import HeaderBo from 'components/backoffice/common/layout/header/HeaderBo'
import ContentBo from 'components/backoffice/common/layout/ContentBo';
import FooterBo from 'components/backoffice/common/layout/FooterBo';
import GdprCompliance from 'components/layout/gdpr/GdprCompliance';


const Layout = ({ children , inverseBackground = false}) => {
    return (
        <div className={`${layoutStyles.root} ${!!inverseBackground? layoutStyles.whiteBackground: layoutStyles.greyBackground}`}>
            <CssBaseline />
            <HeaderBo inverseBackground={inverseBackground}></HeaderBo>
            <ContentBo inverseBackground={inverseBackground}>{children}</ContentBo>
            <FooterBo inverseBackground={inverseBackground}></FooterBo>
            <GdprCompliance></GdprCompliance>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
