import React from "react";

import { Button, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";

import { useIntl } from "gatsby-plugin-intl";
import { useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import { dateTime2lLocaleString } from "utils/date-utils";
import userService from "services/api/userService";
import {  RhfTextField } from "components/backoffice/common/RhfFields";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";


const EditUserProfile = ({ userId = null }) => {
    const intl = useIntl();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const styles = {
        textFields: {
            style: {
                width: isMobile ? "100%" : "75%"
            }
        },
        gridContainer: {
            rowSpacing: 4,
            columnSpacing: 2,
            style: {
                width: "100%", 
                height: "100%",
                margin: "0px",
                padding: "0px 0px 20px",
            }    
        },
        gridTypoUserName: {
            color: "black"
        }
    }

    const [isEditMode, setIsEditMode] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [resultMessage, setResultMessage] = React.useState("");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const userDefaultValues = {
        userId: null,
        firstName: '',
        lastName: '',
        universityAcronym: '',
        universityName: '',
        email: '',
        eppn: '',
        edugainAffiliation: '',
        lastConnectionDate: '',
        creationDate: '',
        creationUser: '',
        creationOrigin:  '',
        lastEditDate: '',
        lastEditUser:  '',
        lastEditOrigin: ''
    };

    const validateUserToEdit = yup.object().shape({
        firstName: yup.string().required("error.field.required"),
        lastName: yup.string().required("error.field.required"),
        email: yup.string()
                    .email("error.email.invalid")
                    .required("error.field.required"),
        eppn: yup.string()
                    .email("error.email.invalid")
                    .required("error.field.required"),
    });

    const useFormHook = useForm(
      {
          resolver: yupResolver(validateUserToEdit),
          defaultValues: userDefaultValues,
          mode: "onChange",
      }
    );
    const {
        reset,
        control,
        formState: { errors, isDirty },
    } = useFormHook;


    const [isEditable, setIsEditable] = React.useState(false);
    const watchedLastConnectionDate = useFormHook.watch("lastConnectionDate");
    React.useEffect(() => {
        if(watchedLastConnectionDate && watchedLastConnectionDate != "") {
            setIsEditable(false);
        } else {
            setIsEditable(true);
        } 
    }, [watchedLastConnectionDate]);

    React.useEffect(() => {
      let subscribed = true;
      const fetchUserData = async () => {
          try {
              const results = await userService.findByUserId(userId);
              const user = results.data;
              if(subscribed) {
                user.lastConnectionDate = user.lastConnectionDate ? dateTime2lLocaleString(user.lastConnectionDate, intl.locale) : "";
                user.lastEditDate = user.lastEditDate ? dateTime2lLocaleString(user.lastEditDate, intl.locale) : "";
                user.creationDate = user.creationDate ? dateTime2lLocaleString(user.creationDate, intl.locale) : "";
                user.university = user.universityAcronym + ' - ' + user.universityName
                user.creationOrigin = intl.formatMessage({id: "lov.creationOrigin." + user.creationOrigin});
                user.lastEditOrigin = intl.formatMessage({id: "lov.lastEditOrigin." + user.lastEditOrigin});
                const newDefaultValues = Object.assign({}, userDefaultValues, user);
                reset(newDefaultValues);
              }
          } catch (error) {
              console.error("error : ", error);
          }
      };
      if (!!userId) {
          fetchUserData();
      }
      return () => { subscribed = false };
    }, []);

    function handleEdit() {
        setIsEditMode(true);
    }


    const handleSaveUserProfile = (userData) => {
        setDisableSubmit(true);
        setOpenSuccess(false);
        setOpenError(false);
        const userProfile = {
            userId: userData.userId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            eppn: userData.eppn,
            universityId: userData.universityId            
        }
        userService
            .modifyUserProfile(userId, userProfile)
            .then((result) => {
                setResultMessage("backoffice.users.edit.profile.success");
                setOpenSuccess(true);
                setDisableSubmit(false);
                setIsEditMode(false);
            })
            .catch((error) => {
                handleError(error);
            });

    };

    const handleError = (error) => {
        let errLabel = "backoffice.users.edit.profile.error";
        if (error && error.data && error.data.errorCode) {
            errLabel = error.data.errorCode;
        }
        console.error(intl.formatMessage({id: errLabel}), error);
        setResultMessage(errLabel);
        setOpenError(true);
        setDisableSubmit(false);
    }

    return (
    <form onSubmit={useFormHook.handleSubmit(handleSaveUserProfile)} noValidate>

        <ResultSnackbars
            openError={openError}
            openSuccess={openSuccess}
            messageId={resultMessage}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
        />  

        <Grid container 
            columnSpacing={ styles.gridContainer.columnSpacing }
            rowSpacing={ styles.gridContainer.rowSpacing } 
            style={ styles.gridContainer.style }
        >

            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="lastName"
                    name={"lastName"} 
                    textTransform="uppercase"
                    disabled={!isEditMode}
                    label={"backoffice.users.edit.profile.lastName"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                    error={useFormHook.formState.errors ? useFormHook.formState.errors.lastName : null}
                />
            </Grid>

            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="firstName"
                    name={"firstName"} 
                    textTransform="capitalize"
                    disabled={!isEditMode}
                    label={"backoffice.users.edit.profile.firstName"}
                    style={styles.textFields.style}
                    control={useFormHook.control}
                    error={useFormHook.formState.errors ? useFormHook.formState.errors.firstName : null}
                />
            </Grid>

            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="university"
                    name={"university"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.university"}
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
           
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="email"
                    name={"email"} 
                    disabled={!isEditMode}
                    label={"backoffice.users.edit.profile.email"}
                    style={styles.textFields.style}
                    control={useFormHook.control}
                    error={useFormHook.formState.errors ? useFormHook.formState.errors.email : null}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="eppn"
                    name={"eppn"} 
                    disabled={!isEditMode}
                    label={"backoffice.users.edit.profile.eppn"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                    error={useFormHook.formState.errors ? useFormHook.formState.errors.eppn : null}
                />
            </Grid>
           
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="edugainAffiliation"
                    name={"edugainAffiliation"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.edugainAffiliation"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="lastConnectionDate"
                    name={"lastConnectionDate"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.lastConnectionDate"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}></Grid>

            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="creationDate"
                    name={"creationDate"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.creationDate"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="creationUser"
                    name={"creationUserEppn"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.creationUser"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="creationOrigin"
                    name={"creationOrigin"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.creationOrigin"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="lastEditDate"
                    name={"lastEditDate"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.lastEditDate"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="lastEditUser"
                    name={"lastEditUserEppn"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.lastEditUser"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
                <RhfTextField 
                    id="lastEditOrigin"
                    name={"lastEditOrigin"} 
                    disabled={true}
                    label={"backoffice.users.edit.profile.lastEditOrigin"} 
                    style={styles.textFields.style}
                    control={useFormHook.control}
                />
            </Grid>
        </Grid>

        <Stack direction="row" spacing={5} margin={5} justifyContent={"center"}>
            { !isEditMode && isEditable ? 
                <Button variant="contained" onClick={handleEdit} disabled={false}>
                    {intl.formatMessage({id: "backoffice.users.edit.editButton"})}
                </Button>
                :
                <></>
            }
            { isEditMode && isEditable ? 
                <Button color="primary" variant="contained" type="submit" disabled={disableSubmit}>
                    {intl.formatMessage({id: "backoffice.users.edit.saveButton"})}
                </Button>
                 : 
                <></>
            }

            { isEditMode && isEditable ?
                <ConfirmationDialog
                    isCancel={true}
                    isModified={isDirty}
                    listingUrl={`/back-office/users/details/${userId}`}
                /> 
                : 
                <ConfirmationDialog
                    listingUrl={`/back-office/users`}
                />
            }
           
        </Stack> 
    
    </form>
    )
}

export default EditUserProfile;