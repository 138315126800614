import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Card, 
    CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useForm} from "react-hook-form";
import {injectIntl } from "gatsby-plugin-intl";

import Seo from "components/common/Seo";
import { RhfListOfValuesSelect, RhfRadioButtonAll, RhfTextField } from 'components/backoffice/common/RhfFields';
import CustomDataGrid from 'components/backoffice/common/CustomDataGrid';
import courseService from "services/api/courseService";
import { COURSE_TYPE, GROUP } from "utils/constants/back-office-constants";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import { containsRole, getUniversityId, getUserRole } from "services/auth";
import { ROLE } from "utils/constants/constants";
import { forEach } from "lodash";

const  LOCAL_STORAGE_SEARCH_CRITERIA = "backoffice.courses.searchCriteria";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20,
    },
    details: {
        alignItems: "center",
    },
    column: {
        flexBasis: "33.33%",
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    textField: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        width: "95%",
    },
    formControlLabel: {
        verticalAlign: "bottom",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(8),
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        "&:hover": {
            borderWidth: "0 0 3px",
            borderStyle: "solid",
            borderColor: "#20a842",
        },
    },
    card: {
        maxHeight: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
        backgroundColor: theme.palette.background.paper,
    },
    cardHeader: {
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
}));

const CourseListPage = ({intl}) => {
    const columns = [
        {
            flex: 1,
            field: "universityName",
            headerName: intl.formatMessage({id: "course.university"}),
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            flex: 1,
            //minWidth:280,
            field: "title",
            headerName: intl.formatMessage({id: "course.title"}),
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            flex: 1,
            //width: 180,
            field: "type",
            headerName: intl.formatMessage({id: "course.type"}),
            renderCell: (params) => {
                const typeCode = params.value;
                return intl.formatMessage({
                    id: courseService.getLabelKeyByGroupAndCode(
                        GROUP.courseType,
                        typeCode
                    ),
                });
            },
        },
        {
            flex: 1,
            //width: 180,
            field: "cycles",
            headerName: intl.formatMessage({id: "course.cycle"}),
            renderCell: (params) => {
                const cycles = params.value;
                return cycles
                    .map((cycle) =>
                        intl.formatMessage({
                            id: courseService.getLabelKeyByGroupAndCode(
                                GROUP.courseCycle,
                                cycle.code
                            ),
                        })
                    )
                    .join(", ");
            },
        },
        {
            flex: 1,
            //width: 180,
            field: "period",
            headerName: intl.formatMessage({id: "course.period"}),
            renderCell: (params) => {
                const typeCode = params.value;
                return typeCode ? intl.formatMessage({
                    id: courseService.getLabelKeyByGroupAndCode(
                        GROUP.period,
                        typeCode
                    ),
                }) : '';
            }
        }
    ];

    const editColumnParams = {
        field: "id",
        arialLabel: "modify course",
        url: "/back-office/courses/edit"
    }

    const methods = useForm({
        defaultValues: {
            textFilter: "",
            courseType: "",
            courseSubtype: "",
            courseUniversity: "",
            courseCycle: "",
            coursePeriod: "",
            courseCode: "",
            courseTeacher: "",
            privateCourse: "",
            visible: "",
            approved: "",
        },
    });

    const classes = useStyles();
    const pageSize = 50;

    const [totalNumberOfRow, setTotalNumberOfRow] = React.useState(0);
    const searchCriteria = React.useRef({});

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [page, setPage] = React.useState(0);

    const submitForm = (formData) => {
        searchCriteria.current = formData;
        localStorage.setItem(LOCAL_STORAGE_SEARCH_CRITERIA, JSON.stringify(searchCriteria.current));
        loadServerRows(0, []);
    };

    const resetForm = () => {
        methods.reset();
        searchCriteria.current = methods.getValues();
        localStorage.removeItem(LOCAL_STORAGE_SEARCH_CRITERIA);
        loadServerRows(0, []);
    };

    const loadServerRows = React.useCallback(async (page, sortModel) => {
        setDisableSubmit(true);
        setLoading(true);
        let response = null;
        try {
            response = await courseService.searchByCriteria(
                searchCriteria.current,
                page,
                pageSize,
                sortModel
            ); 
        } catch (error) {
            console.error(error);
            setLoading(false);
            setDisableSubmit(false);
        }
        
        let courses = [];
        if(response?.data?.content){
            courses = response.data.content.map(row => (
                { ...row, 
                    deletable: row.deletable &&  (row.university == getUniversityId() || containsRole([ROLE.admin])),
                    editable: getUniversityId() == row.university || containsRole([ROLE.admin])
                }
            ));
        }
        setPage(page);
        setTotalNumberOfRow(response?.data?.totalSize);
        setRows(courses);
        setDisableSubmit(false);
        setLoading(false);
    }, []);

    const handleDelete = async (selection) => {
        // delete selected rows
        await courseService.deleteCourses(selection).then(() => {
            setRows(
                rows.filter((r) => selection.filter((sr) => sr === r.id).length < 1)
            );
            setSelectionModel([]);
        });
    };

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    let displaySubType = methods.watch("courseType") === COURSE_TYPE.additional;

    React.useEffect(() => {
        let item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_CRITERIA));
        if(item){
            searchCriteria.current = item;
            Object.keys(item).forEach(key => {
                methods.setValue(key,item[key])
            });
        }
        loadServerRows(0, []);
        setRows([]);
    }, [loadServerRows]);

        return (
        <LayoutBo>
            <Seo
                title={intl.formatMessage({id: "course.catalog"})}
                lang={intl.locale}
            />
            <Card className={classes.card}>
               <CardContent className={classes.cardContent}>
                    <form onSubmit={methods.handleSubmit(submitForm)} noValidate>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary  id="filter-title" expandIcon={<ExpandMoreIcon/>}>
                                        <div className={classes.column}>
                                            <Typography className={classes.heading}>
                                                {intl.formatMessage({id: "backoffice.filter.title"})}
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.details}>
                                        <Grid item container xs={12}>
                                            <Grid item xs={12}>
                                                <RhfTextField id="filter-textFilter"
                                                    name="textFilter"
                                                    label={"backoffice.filter.fullText.label"}
                                                    helpMessage={intl.formatMessage({
                                                        id: "backoffice.courses.filter.fullText.helpMessage",
                                                    })}
                                                    control={methods.control}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                                <RhfListOfValuesSelect id="filter-courseType"
                                                    name="courseType"
                                                    label={"course.type"}
                                                    group={GROUP.courseType}
                                                    control={methods.control}
                                                    className={classes.textField}/>
                                            </Grid>

                                            {displaySubType && (
                                                <Grid item lg={3} md={4} sm={12} xs={12}>
                                                    <RhfListOfValuesSelect id="filter-courseSubtype"
                                                        name="courseSubtype"
                                                        label={"course.subtype"}
                                                        group={GROUP.courseSubtype}
                                                        control={methods.control}
                                                        className={classes.textField}/>
                                                </Grid>
                                            )}

                                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                                <RhfListOfValuesSelect id="filter-courseUniversity"
                                                    name="courseUniversity"
                                                    label={"course.university"}
                                                    group={GROUP.university}
                                                    control={methods.control}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                                <RhfListOfValuesSelect id="filter-courseCycle"
                                                    name="courseCycle"
                                                    label={"course.cycle"}
                                                    group={GROUP.courseCycle}
                                                    control={methods.control}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                                <RhfListOfValuesSelect id="filter-coursePeriod"
                                                    name="coursePeriod"
                                                    label="course.period"
                                                    group={GROUP.period}
                                                    control={methods.control}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                                <RhfTextField id="filter-courseCode"
                                                    name="courseCode"
                                                    label={"course.code"}
                                                    control={methods.control}
                                                    className={classes.textField}                                                    
                                                />
                                            </Grid>
                                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                                <RhfListOfValuesSelect id="filter-courseTeacher"
                                                    name="courseTeacher"
                                                    label={"course.teachers"}
                                                    group={GROUP.teacher}
                                                    control={methods.control}
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={8}
                                                >
                                                    <RhfRadioButtonAll id="filter-privateCourse"
                                                        name="privateCourse"
                                                        label="course.private.course"
                                                        control={methods.control}                                                        defaultValue = ""
                                                    />
                                                    <RhfRadioButtonAll id="filter-visible"
                                                        name="visible"
                                                        label="course.visible"
                                                        control={methods.control}                                                        defaultValue = ""
                                                    />
                                                    <RhfRadioButtonAll id={"filter-approved"}
                                                        name="approved"
                                                        label="course.approved"
                                                        control={methods.control}
                                                        defaultValue = ""
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    <Divider/>
                                    <AccordionActions style={
                                        {
                                            justifyContent: 'center'
                                        }
                                    }>
                                        <Button color="secondary" onClick={resetForm}>
                                            {intl.formatMessage({id: "course.filter.reset"})}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={disableSubmit}
                                        >
                                            {intl.formatMessage({id: "course.filter.search"})}
                                        </Button>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </form>
                    
                    <CustomDataGrid
                        columns={columns}
                        editColumnParams={editColumnParams}
                        onDelete={handleDelete}
                        loadServerRows={loadServerRows}
                        pageSize={pageSize}
                        totalRows={totalNumberOfRow}
                        changeSelection={handleSelectionChange}
                        selectionModel={selectionModel}
                        rows={rows}
                        addMessage="course.add"
                        loading={loading}
                        addLink="/back-office/courses/new"
                        rowDoubleClickLink="/back-office/courses/edit"
                        page={page}
                    />
                </CardContent>
            </Card>

        </LayoutBo>
    );
};

export default injectIntl(CourseListPage);
