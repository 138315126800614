import React from "react";

import { Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from "gatsby-plugin-intl";

import courseService from "services/api/courseService";
import userService from "services/api/userService";
import { containsRole } from "services/auth";

import { capitalizeFirstLetter } from "utils/string-utils";
import { ROLE } from "utils/constants/constants";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import DeleteConfirmationDialog from "ui/DeleteConfirmationDialog";
import AutoCompleteMonoValue from "ui/AutoCompleteMonoValue";
import { getCodeLabel, getCourseLabel, getPeriodLabel, getTypeLabel } from "components/catalog/common/CourseUtils";



const EditUserCourses = ({ userId = null }) => {
    const intl = useIntl();
    const styles = {
        gridContainer: {
            rowSpacing: 1,
            columnSpacing: 1,
            style: {
                width: "100%", 
                height: "100%",
                margin: "0px 0px 0px",
                padding: "0px 0px 0px"
            }    
        },
        typoUserName: {
            color: "grey",
            fontWeight: 400
        },
        typoTitle: {
            color: "grey",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: 0.0093
        },
        gridAddButton: {
            //border: "1px solid red",
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
            marginBottom: "20px",
            paddingLeft: "25px"
        },
        gridCourseSelect: {
            //border: "1px solid red",
            marginBottom: "20px"
        },
        gridItem: {
            rowSpacing: 0,
            columnSpacing: 0,
            style: {
                //border: "1px solid green",
                width: "100%", 
                height: "100%",
                margin: "0px 0px 0px",
                padding: "0px 8px 0px",
            }
        },
        typoCourse: {
            display: "inline"
        },
        listItem: {
            text: {
                root: {
                    fontSize: "1rem"
                }
            }
        }
    }

    const [user, setUser] = React.useState({
        userId: null,
        firstName: "",
        lastName: "",
        universityId: null,
        universityAcronym: "",
        universityName: "",
        courses: []
    });

    const [resultMessage, setResultMessage] = React.useState("");
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = React.useState(false);
    const [courseToAddItem, setCourseToAddItem] = React.useState(null);
    const [courseToDeleteId, setCourseToDeleteId] = React.useState(null);
    const [courseToDeleteLabel, setCourseToDeleteLabel] = React.useState(null);
    const [courses, setCourses] = React.useState([]);
    const [coursesLoading, setCoursesLoading] = React.useState(false);

  
    React.useEffect(() => {
      let subscribed = true;
      const fetchUserCourses = async () => {
        try {
            const results = await userService.findUserCoursesByUserId(userId);
            const user = results.data;
            if(subscribed) {
              setUser(user);
            }
        } catch (error) {
            console.error("error : ", error);
        }
    };
      if (!!userId) {
        fetchUserCourses();
      }
      return () => { subscribed = false; };
    }, []);


    React.useEffect(
        () => {
            fetchCoursesNotEnrolled();
        }, []
    );

    const fetchUserCourses = async () => {
        try {
            const results = await userService.findUserCoursesByUserId(userId);
            const user = results.data;
            setUser(user);
        } catch (error) {
            console.error("error : ", error);
        }
    };

    const fetchCoursesNotEnrolled = async () => {
        setCoursesLoading(true);
        setCourses([]);
        courseService.findCoursesNotEnrolledByUserId(userId)
            .then((results) => {
                let courses = [];
                if(results?.data){
                    courses = results.data.map(
                        (course) => {
                            return (
                                { 
                                    value: course.id, 
                                    label: getCourseLabel(intl, course)
                                }
                            )
                        }
                    );
                }
                setCourses(courses);
                setCoursesLoading(false);
            }).catch((error) => {
                setCoursesLoading(false);
                console.log(error);
            });
    }

    const handleAddCourse = async (event) => {
        setOpenSuccess(false);
        setOpenError(false);
        const userCourseInfos = {
            courseId: courseToAddItem.value,
            userId: userId,
            userUniversityId: user?.universityId
        };
        userService
            .addCourseToUser(userCourseInfos)
            .then((result) => {
                setResultMessage("backoffice.users.edit.courses.add.success");
                fetchUserCourses();
                fetchCoursesNotEnrolled();
                setCourseToAddItem("");
                setOpenSuccess(true);
            })
            .catch((error) => {
                handleCallApiError(error, "backoffice.users.edit.courses.add.error");
            });
    }

    

    const handleClickDelete = (course) => {
        setOpenDeleteConfirmDialog(true);
        setCourseToDeleteId(course.courseId);
        setCourseToDeleteLabel(getCourseLabel(intl, course));
    }

    const handleCloseDeleteConfirmDialog = () => {
        setOpenDeleteConfirmDialog(false);
    }

    const handleDeleteCourse = () => {
        setOpenSuccess(false);
        setOpenError(false);
        const userCourseInfos = {
            courseId: courseToDeleteId,
            userId: userId,
            userUniversityId: user?.universityId
        };
        userService
            .deleteCourseToUser(userCourseInfos)
            .then((result) => {
                setResultMessage("backoffice.users.edit.courses.delete.success");
                setOpenSuccess(true);
                fetchUserCourses();
                fetchCoursesNotEnrolled();
            })
            .catch((error) => {
                handleCallApiError(error, "backoffice.users.edit.courses.delete.error");
            });
        setOpenDeleteConfirmDialog(false);
    }

    const handleCallApiError = (error, errorLabel) => {
        if (error && error.data && error.data.errorCode) {
            errorLabel = error.data.errorCode;
        }
        console.error(intl.formatMessage({id: errorLabel}), error);
        setResultMessage(errorLabel);
        setOpenError(true);
    }

    let coursesList = user?.courses.map((course, i) => {

        const periodLabel = getPeriodLabel(intl, course.period);
        const typeLabel = getTypeLabel(intl, course.type);
        const codeLabel = getCodeLabel(course.code);
        const codeTitle = course.title ? course.title : "No title";
        const teacher = course.isTeacher && course.isTeacher == "true" ? 
            "("+ intl.formatMessage({ id: "backoffice.users.edit.courses.teacher"}) + ")" : "";

        return (
            <ListItem key={course.courseId}>
                <ListItemAvatar>
                    <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={e => handleClickDelete(course)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </ListItemAvatar>
                <ListItemText
                    style={styles.listItem.text}
                    primary={`${periodLabel} - ${typeLabel} - ${codeLabel} - ${codeTitle} ${teacher}`}
                />
            </ListItem>
        );
    });


    return (
    <>

        <ResultSnackbars
            openError={openError}
            openSuccess={openSuccess}
            messageId={resultMessage}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
        />  

        <Grid container 
            columnSpacing={ styles.gridContainer.columnSpacing }
            rowSpacing={ styles.gridContainer.rowSpacing } 
            style={ styles.gridContainer.style }
        >
            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "left" }}>
                <Typography sx={styles.typoUserName}>
                    { capitalizeFirstLetter(user?.firstName) + ' ' + user?.lastName?.toUpperCase() 
                    + ' ( ' +  user?.universityAcronym+ ' - ' + user?.universityName +  ' )'
                    }
                </Typography>
            </Grid>
         
            { containsRole([ROLE.admin, ROLE.admin_university]) && 
                <Grid item xs={12} md={8} 
                    textAlign={{ xs: "left", md: "center" }}
                    style={styles.gridCourseSelect}
                >
                    <AutoCompleteMonoValue 
                        id="select-course"
                        loading={coursesLoading}
                        label={"backoffice.users.edit.courses.selectCourse"} 
                        value={courseToAddItem}
                        setValue={setCourseToAddItem} 
                        options={courses}
                    />
                </Grid>
            }
        
            { containsRole([ROLE.admin, ROLE.admin_university]) &&
                <Grid item xs={12} md={4} 
                        textAlign={{ xs: "left", md: "center" }}
                        style={styles.gridAddButton}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddCourse}
                        disabled={ courseToAddItem == undefined || courseToAddItem == "" }
                    >
                        {intl.formatMessage({ id: "backoffice.users.edit.courses.addButton" })} 
                    </Button>      
                </Grid>
            }

            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "left" }}>
                <Typography sx={styles.typoTitle} variant="h6" component="div">
                    { intl.formatMessage({id: "backoffice.users.edit.courses.title"})}
                </Typography>
            </Grid>

            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "center" }}>
                <List>
                    { coursesList }
                </List>
            </Grid>

            <DeleteConfirmationDialog 
                    open={openDeleteConfirmDialog}
                    itemToDeleteLabel={courseToDeleteLabel}
                    onDelete={handleDeleteCourse} 
                    onClose={handleCloseDeleteConfirmDialog} 
                    onCancel={handleCloseDeleteConfirmDialog}            
            />

        </Grid> 
           
        <Stack direction="row" spacing={5} margin={5} justifyContent={"center"}>
        <ConfirmationDialog listingUrl={`/back-office/users`} />
        </Stack> 
    
    </>
    )
}
export default EditUserCourses;
