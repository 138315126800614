import React from 'react';
import * as headerStyles from 'styles/backoffice/header.module.scss';

import { Link, useIntl } from 'gatsby-plugin-intl';
import { containsRole } from 'services/auth';
import { ROLE } from 'utils/constants/constants';

export const menuItems =  [
    //{ id:"dashboard", title: "backoffice.header.navbar.dashboard", link: "/back-office/dashboard", authorizedRoles: [ROLE.admin, ROLE.back_office_manager, ROLE.academics_manager, ROLE.courses_approver, ROLE.courses_manager, ROLE.news_manager]},
    // { id:"certificates", title: "backoffice.header.navbar.certificates", link: "/back-office/certificates", authorizedRoles: [ROLE.admin, ROLE.back_office_manager] },
    // { id:"library", title: "backoffice.header.navbar.library", link: "/back-office/library", authorizedRoles: [ROLE.admin, ROLE.back_office_manager] },
    { id:"courses", title: "backoffice.header.navbar.catalog", link: "/back-office/courses", authorizedRoles: [ROLE.admin, ROLE.admin_university, ROLE.courses_approver, ROLE.courses_manager] },
    { id:"enrollments", title: "backoffice.header.navbar.enrollments", link: "/back-office/enrollments", authorizedRoles: [ROLE.admin, ROLE.admin_university] },
    { id:"academics", title: "backoffice.header.navbar.academics", link: "/back-office/faculty", authorizedRoles: [ROLE.admin, ROLE.admin_university, ROLE.academics_manager]},
    { id:"news", title: "backoffice.header.navbar.news", link: "/back-office/opportunities", authorizedRoles: [ROLE.admin, ROLE.admin_university, ROLE.news_manager] },
    { id:"users", title: "backoffice.header.navbar.users", link: "/back-office/users", authorizedRoles: [ROLE.admin, ROLE.admin_university] },
];

export default function NavbarBo() {
    const intl = useIntl();
    const menu = menuItems.map((item) =>
        <div className={headerStyles.navBarDesktop} key={item.id}>
            { process.env.SHOW_ADMIN && containsRole(item.authorizedRoles) ?
                (<div>
                    <Link to={item.link} className={headerStyles.link}
                     activeClassName={headerStyles.linkSelected}> 
                        {intl.formatMessage({id: item.title})}
                    </Link>
                </div>) : null
            }
        </div>
    );
    return (<>{ menu }</>);
}